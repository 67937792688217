import React, { useEffect, useState } from "react";
import { FormGroup, Input, Modal, ModalBody, ModalHeader, NavLink } from "reactstrap";
import { useNavigate } from "react-router-dom";


const DeactiveAccountModel = ({ deactivateAccount, openDeactivateModel, setOpenDeactivateModel }) => {
  return (
    <>
      

      <Modal isOpen={openDeactivateModel} size="lg"  centered className="profilePermission" backdrop={true} >
        <ModalBody className="p-3">
          <div className="modal-header d-flex flex-column  p-5 text-white">
            <h3 className='text-center'>Deactivate Account?</h3>
            <p className='text-center mt-3'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.<br />putate
              libero et velit interdum, ac aliquet odio mattis.{" "}
            </p>
          </div>

          <div className='d-flex flex-column align-items-center'>
            <div className='d-flex justify-content-center align-items-center'>
            <div
          className="noble-login-btns mb-3 px-5 py-2"
          onClick={deactivateAccount}
        >
          Continue
        </div>
        <div
          className="noble-login-btns mb-3 px-5 py-2 mx-3"
          onClick={() => setOpenDeactivateModel(!openDeactivateModel)}
        >
          Cancel
        </div>
            </div>       
          </div>
          
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeactiveAccountModel;
