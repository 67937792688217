import React, { useState } from "react";
import Sidebar from "../Componenets/Sidebar";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "../WorkerPoolProfile.scss";
import "../../WorkerPoolFormPages/WorkerPoolDefaultForm.scss";
import { Link } from "react-router-dom";
import backArrow from "../../../assets/img/backArrow.svg";
import { call } from "../../../api/apiCall";
import { toast } from "react-toastify";
import FormInput from "../../../core/Inputs/FormInput";

const ChangePassword = (props) => {
  const { setProfileField } = props;
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassErrorMessage, setOldPassErrorMessage] = useState("");
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [confirmPassErrorMessage, setConfirmPassErrorMessage] = useState("");
  const token = localStorage.getItem("accessToken");

  const validation = () => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!oldPassword) {
      setOldPassErrorMessage("Please Enter Your Old Password");
      return;
    }
    if (!password) {
      setPassErrorMessage("Please Enter Your Password");
      return;
    }
    if (!passwordValid.test(password)) {
      setPassErrorMessage(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      return;
    }
    if (password === oldPassword) {
      setPassErrorMessage(
        "Your new password should be different from old Password"
      );
      return;
    }
    console.log("passw", password);
    console.log("confirmPassword", confirmPassword);
    if (!(password === confirmPassword)) {
      setConfirmPassErrorMessage("Passwords do not match");
      return;
    }

    handleSubmit();
  };
  const handelOldPassChange = (e) => {
    setOldPassword(e.target.value);
    setOldPassErrorMessage("");
    setPassErrorMessage("");
  };
  const handelPassChange = (e) => {
    setPassword(e.target.value);
    setPassErrorMessage("");
    setConfirmPassErrorMessage("");
  };
  const handelConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPassErrorMessage("");
  };
  const handleSubmit = async () => {
    let resetPassData = {
      oldPassword: oldPassword,
      newPassword: password,
    };

    try {
      const res = await call(
        "post",
        "api/v1/worker/change-password",
        null,
        resetPassData,
        token
      );
      console.log("res", res);
      if (res.data.success) {
        toast.success("Password has been changed successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        // alert("Password has been changed successfully.")
        setProfileField("setting");
      }
      if (res.success === false) {
        toast.error(res.message);
      }
    } catch (err) {
      console.log(err);
      if (
        err.response.data.message ===
        "The old-password you have entered is wrong."
      )
        setOldPassErrorMessage(err.response.data.message);
    }
  };
  return (
    // <div className="profile-dashboard-main">
    //     <div className='profile-container'>
    //         <div className="row">
    //             <div className="col-xxl-3 col-lg-2 col-1">
    //                 <Sidebar />
    //             </div>
    //             <div className="col-xxl-9 col-lg-10 col-12">
    <div className="profile-content-outer">
      <div className="workerpool-default-form-page">
        <div onClick={() => setProfileField("setting")} className="back-btn">
          <img src={backArrow} alt="Icon" />
        </div>
        <div className="head progress-box">
          <h3>Change Password</h3>
          <p>
            Your password must be between 6-24 characters, include both
            uppercase and lowercase letters (e.g., a-z and A-Z), and contain at
            least one number (e.g., 0-9).
          </p>
        </div>
        <form className="form-box">
          <div className="row">
            <FormGroup className="col-sm-12">
              {/* <Label>Current Password</Label>
              <Input type="password" placeholder="Enter Current Password" /> */}
              <FormInput
                label="Current Password"
                type="password"
                placeholder="Enter current password"
                maxlength="24"
                onChange={(e) => handelOldPassChange(e)}
                error={oldPassErrorMessage}
              />
            </FormGroup>
            <FormGroup className="col-sm-12">
              {/* <Label>New password</Label>
              <Input type="password" placeholder="Enter New password" /> */}
              <FormInput
                label="New password"
                type="password"
                placeholder="Enter new password"
                maxlength="24"
                onChange={(e) => handelPassChange(e)}
                error={passErrorMessage}
              />
            </FormGroup>
            <FormGroup className="col-sm-12">
              {/* <Label>Confirm password</Label>
              <Input type="password" placeholder="Enter Confirm password" /> */}
              <FormInput
                label="Confirm password"
                type="password"
                placeholder="Enter confirm password"
                maxlength="24"
                onChange={(e) => handelConfirmPassChange(e)}
                error={confirmPassErrorMessage}
              />
            </FormGroup>
          </div>
          <div className="links-outer d-inline-flex flex-column gap-4 mt-4">
            {/* <Link to="#" className="font-15">
              Forgot Password
            </Link> */}
            <Button className="blue-btn" onClick={() => validation()}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
    //             {/* </div>
    //         </div>
    //     </div>
    // </div> */}
  );
};

export default ChangePassword;
