import React, { useState } from "react";
import profileDashboardIcon1 from "../../../assets/img/profileDashboardIcon-1.png";
import profileDashboardIcon2 from "../../../assets/img/profileDashboardIcon-2.png";
import profileDashboardIcon3 from "../../../assets/img/profileDashboardIcon-3.png";
import profileDashboardIcon4 from "../../../assets/img/profileDashboardIcon-4.png";
import profileDashboardIcon5 from "../../../assets/img/profileDashboardIcon-5.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = (props) => {
  const { setProfileField, profileField } = props;

  const [isActive, setIsActive] = useState(false);
  const { user } = useSelector((state) => state.auth);
  console.log(user?.worker?.type, "user");

  const handleButtonClick = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <button class="sidebar-navbar-toggler" onClick={handleButtonClick}>
        <span class="sidebar-navbar-icon"></span>
        <span class="sidebar-navbar-icon"></span>
        <span class="sidebar-navbar-icon"></span>
      </button>
      <div className={isActive ? "sidebar active" : "sidebar "}>
        <div
          className={profileField == "profile" ? "sidetabs active" : "sidetabs"}
          onClick={() => {
            setProfileField("profile");
            setIsActive(true);
            handleButtonClick();
          }}
        >
          <img src={profileDashboardIcon1} alt="Icon" /> Profile
        </div>
        {user?.worker?.type == "worker" && (
          <div
            className={
              profileField == "resume" ? "sidetabs active" : "sidetabs"
            }
            onClick={() => {
              setProfileField("resume");
              handleButtonClick();
            }}
          >
            <img src={profileDashboardIcon2} alt="Icon" /> Resume
          </div>
        )}
        {user?.worker?.type == "provider" && (
          <div
            className={profileField == "job" ? "sidetabs active" : "sidetabs"}
            onClick={() => {
              setProfileField("job");
              handleButtonClick();
            }}
          >
            <img src={profileDashboardIcon3} alt="Icon" /> My Jobs
          </div>
        )}
        <div
          className={profileField == "setting" ? "sidetabs active" : "sidetabs"}
          onClick={() => {
            setProfileField("setting");
            handleButtonClick();
          }}
        >
          <img src={profileDashboardIcon4} alt="Icon" /> Settings
        </div>
        <div
          className="sidetabs"
          onClick={() => {
            window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/help`);
            handleButtonClick();
          }}
        >
          <img src={profileDashboardIcon5} alt="Icon" /> Help
        </div>
      </div>
    </>
  );
};

export default Sidebar;
