import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import whiteCallIcon from "../../assets/img/phoneIcon.svg";
import whiteWhatsappIcon from "../../assets/img/whatsappIcon.svg";
import whiteMailIcon from "../../assets/img/emailIcon.svg";
import placeholderUser from "../../assets/img/userDefaultImage.svg";
import { useSelector } from "react-redux";
import LoginModal from "../login/component/LoginModal";
import { profileApi } from "../../api/profileApi";

const WorkCard = (props) => {
  const { workers, allWorkerList } = props;
  const [loginPopUp, setLoginPopUp] = useState(false);
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [phoneNum, setPhoneNum] = useState("");
  const [mailTo, setMailTo] = useState("");
  const [whatsAppTo, setWhatsAppTo] = useState("");
  const navigate = useNavigate();
  const handleNavigate = () => {
    // if (isLoggedIn) {
    profileApi.createSearchHistory({
      platform_type: "wp",
      type: "worker",
      type_id: workers?.user?.id,
      search: workers?.user?.first_name + " " + workers?.user?.last_name,
    });
    navigate(`/workers/profile/${workers?.id}`);
    // } else {
    //   setLoginPopUp(true);
    // }
  };

  const handleContactNavigate = () => {
    if (isLoggedIn) {
      navigate(`/workers/profile/${workers?.id}`);
    } else {
      setLoginPopUp(true);
    }
  };

  const handleLink = (link, type) => {
    const alr = window.confirm(`Do you want to continue with ${type}`);
    if (alr) window.open(link, "_blank");
  };

  useEffect(() => {
    if (workers?.user?.phone) {
      setPhoneNum(`tel:${workers?.user?.phone}`);
    }
    if (workers?.whatsapp) {
      let newText = workers?.whatsapp?.split("-")[1] || workers?.whatsapp;
      setWhatsAppTo(`https://api.whatsapp.com/send?phone=${newText}`);
    }
    if (workers?.email) {
      setMailTo(`mailto:${workers?.email}`);
    }
  }, [workers]);

  return (
    <div
      className={"col-inner" + (!allWorkerList ? " col-inner-dashoard" : "")}
    >
      <div className="profile-box-outer">
        <div className="profile-box">
          <img
            src={
              workers?.user?.profile_img
                ? workers?.user?.profile_img
                : placeholderUser
            }
            alt="Icon"
            className="img-fluid"
          />
          <div className="profile-head" onClick={() => handleNavigate()}>
            <h3 className="card-control-text-oneline">
              {workers?.user?.first_name + " " + workers?.user?.last_name}
            </h3>
            <span className="location card-control-text-oneline">
              {workers?.desire_country}
            </span>
          </div>
        </div>
        <div className="icon-box" style={{ cursor: "pointer" }}>
          {phoneNum && (
            <div>
              {isLoggedIn ? (
                // <div className="" onClick={() => handleLink(phoneNum)}>
                <Link to={phoneNum} target="_blank">
                  <div className="mail-icon">
                    <img
                      width={50}
                      height={50}
                      src={whiteCallIcon}
                      alt="Icon"
                    />
                  </div>
                </Link>
              ) : (
                // </div>

                <div className="mail-icon" onClick={() => setLoginPopUp(true)}>
                  <img width={50} height={50} src={whiteCallIcon} alt="Icon" />
                </div>
              )}
            </div>
          )}
          {mailTo && (
            <div>
              {isLoggedIn ? (
                <div className="" onClick={() => handleLink(mailTo, "mail")}>
                  <div className="mail-icon">
                    <img
                      width={50}
                      height={50}
                      src={whiteMailIcon}
                      alt="Icon"
                    />
                  </div>
                </div>
              ) : (
                <div className="mail-icon" onClick={() => setLoginPopUp(true)}>
                  <img width={50} height={50} src={whiteMailIcon} alt="Icon" />
                </div>
              )}
            </div>
          )}
          {whatsAppTo && (
            <div>
              {isLoggedIn ? (
                <div
                  className="whatsapp-icon"
                  onClick={() => handleLink(whatsAppTo, "whatsapp")}
                >
                  <img
                    src={whiteWhatsappIcon}
                    width={50}
                    height={50}
                    alt="Icon"
                  />
                </div>
              ) : (
                <div
                  className="whatsapp-icon"
                  onClick={() => setLoginPopUp(true)}
                >
                  <img
                    width={50}
                    height={50}
                    src={whiteWhatsappIcon}
                    alt="Icon"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {workers?.profession?.length > 0 && (
        <div className="skills-box">
          <h4>What i do</h4>
          <span className="skills">
            <ul>
              {/* {workers?.profession.map((item, index) => ( */}
              <li>{workers?.profession.join()}</li>
              {/* ))} */}
            </ul>
          </span>
        </div>
      )}
      <div className="details">
        <p className="card-control-text-fourline">
          {workers?.user?.profile?.about}
        </p>
      </div>
      <div className="box-outer">
        <div style={{ display: "flex", gap: "10px" }}>
          <div className="box">
            <h5>Experience</h5>
            {workers?.experience ? (
              <span className="box-info">
                {workers?.experience}{" "}
                {workers?.experience == 1 ? "year" : "years"}
              </span>
            ) : (
              <span className="box-info">Fresher</span>
            )}
          </div>
          <div className="box">
            <h5>Preference</h5>
            <span className="box-info">{workers?.work_type}</span>
          </div>
          <div className="box">
            <h5>Profile</h5>
            <span className="box-info">
              {workers?.is_public ? "Public" : "Private"}
            </span>
          </div>
        </div>
        <div>
          <div className="mt-2">
            {workers?.created_by === "independent" ? (
              <span
                className="profileType public p-2 "
                style={{
                  background: "rgba(0, 168, 67, 0.10)",
                  borderRadius: "10px",
                }}
              >
                Independent
              </span>
            ) : (
              <span
                className="profileType private p-2"
                style={{
                  background: "rgba(255, 68, 43, 0.10)",
                  borderRadius: "10px",
                }}
              >
                Agency
              </span>
            )}
          </div>
        </div>
      </div>
      {loginPopUp && (
        <LoginModal
          modal={loginPopUp}
          toggle={() => setLoginPopUp(!loginPopUp)}
          workerId={workers?.id}
          setLoginPopUp={setLoginPopUp}
        />
      )}
    </div>
  );
};
export default WorkCard;
