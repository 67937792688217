import React, { useState } from "react";
import workerPoolLogo from "../../assets/img/workerLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import { logout } from "../../store/auth/authActions";
import { toast } from "react-toastify";
import profileIcon from "../../assets/img/viewProfileIcon.svg";
import searchIcon from "../../assets/img/searchHistoryIcon.svg";
import logoutIcon from "../../assets/img/logoutIcon.svg";
import settingIcon from "../../assets/img/settingIcon.svg";
const HeaderLogo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const profileButtonClick = () => {
    setIsActive(!isActive);
  };
  const handleLogout = async () => {
    try {
      dispatch(logout())
        .then((res) => {
          if (res.success) {
            toast.success(res?.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            navigate("/");
            localStorage.clear();
          }
        })
        .catch(() => {
          navigate("/");
        });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <header className="header-logo-only">
      <div className="container" style={{ maxWidth: "2120px" }}>
        <div
          className="navbar navbar-expand-lg navbar-light worker-header "
          style={{ padding: "20px 35px" }}
        >
          <div className="navbar-brand logo" onClick={() => navigate("/")}>
            <img src={workerPoolLogo} alt="logo" className="pt-2" />
          </div>
          {/* <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button> */}
          {/* {!isProfilePage && !isJobDetailPage && ( */}
          {/* {!isProfilePage && ( */}
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav ml-auto right-btn">
              {isLoggedIn && (
                <li
                  className="nav-item profile-box d-flex align-items-center gap-2"
                  onClick={profileButtonClick}
                >
                  <span className="profile-user">{user?.membership?.name}</span>
                  <img
                    src={
                      user?.profile_img ? user?.profile_img : placeholderUser
                    }
                    alt="Icon"
                  />
                  {isActive && (
                    <div
                      className={
                        isActive
                          ? "profile-sub-list show-profile border border-1"
                          : "profile-sub-list border border-1"
                      }
                    >
                      <Link
                        to="/worker/profile-dashboard"
                        style={{ border: "none", background: "none" }}
                      >
                        <img src={profileIcon} className="icon_img" />
                        View Profile
                      </Link>
                      {/* <Link
                  to="/"
                  onClick={() =>
                    alert(
                      "This feature will be implemented in upcomming milestones"
                    )
                  }
                  style={{ border: "none", background: "none" }}
                >
                  Manage Workers
                </Link> */}
                      <Link
                        to={{
                          pathname: "/worker/profile-dashboard",
                          search: new URLSearchParams({
                            type: "setting",
                          }).toString(),
                        }}
                        style={{ border: "none", background: "none" }}
                      >
                        <img src={settingIcon} className="icon_img" />
                        Settings
                      </Link>
                      <Link
                        to="/search-history"
                        style={{ border: "none", background: "none" }}
                      >
                        <img src={searchIcon} className="icon_img" />
                        Search History
                      </Link>
                      <div
                        className="logout-btn"
                        onClick={() => handleLogout()}
                      >
                        <img src={logoutIcon} className="icon_img" />
                        Log Out
                      </div>
                    </div>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderLogo;
