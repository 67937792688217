import { toast } from "react-toastify";
import { dashboardApi } from "../../api/dashboardApi";
import { providerApi } from "../../api/provider";
import { initPageLoad, stopLoad, stopPageLoad } from "../loader/loaderActions";
import {
  DELETE_MANAGEWORKER_PROFILE,
  GET_MANAGEWORKER_LIST,
  FAIL_GET_WORKER_LIST,
  UPDATE_MANAGEWORKER_PROFILE,
} from "../types";

export const getCompanyWorker = (params) => (dispatch, getState) => {
  let { manageWorker } = getState().provider;
  dispatch(initPageLoad());
  return providerApi.getWorkerOfPage(params).then(
    (response) => {
      dispatch({
        type: GET_MANAGEWORKER_LIST,
        // payload: response
        payload:
          manageWorker && params?.skip && params?.limit
            ? {
                ...response.data,
                data: [...manageWorker.data, ...response.data],
              }
            : response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error.response.data.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getWorker = ({id,token}) => (dispatch) => {
  dispatch(initPageLoad());
  return providerApi.getWPuserbyId(id,token).then(
    (response) => {
      dispatch(stopPageLoad());
      console.log(response);
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      toast.error(error.response.data.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const makeProfilePublic = (ids) => (dispatch) => {
  return providerApi.ProfilePublic(ids).then(
    (response) => {
      dispatch({
        type: UPDATE_MANAGEWORKER_PROFILE,
        payload: ids,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const deleteWorkers = (ids) => (dispatch) => {
  return providerApi.DeleteProfile(ids).then(
    (response) => {
      dispatch({
        type: DELETE_MANAGEWORKER_PROFILE,
        payload: ids,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};
