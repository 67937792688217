import React, { useState } from "react";
import "./Input.scss";
import eyeOff from "../../assets/img/eyeOff.svg";
import eye from "../../assets/img/eye.svg";
const FormInput = ({
  label,
  icon,
  type,
  onChange,
  error,
  containerClassName,
  readOnly,
  ...otherProps
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div className={` ${containerClassName}`}>
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <div className="d-flex position-relative form-input align-items-center">
       {icon && <img className="input-icon" src={icon} alt="" />}
        <input
          className={"form-input-input"+(icon?" form-control":" form-wt-icon-control")}
          type={type === "password" && passwordShown ? "text" : type}
          onChange={onChange}
          readOnly={readOnly}
          {...otherProps}
        />
        {type === "password" && (
          <span onClick={togglePassword} className="form-input-eye">
            {passwordShown ? (
              <img src={eye} alt="" className="form-input-eye-icon" />
            ) : (
              <img src={eyeOff} alt="" className="form-input-eye-icon" />
            )}
          </span>
        )}
      </div>
      {error && <label className="label-input100 text-danger">{error}</label>}
    </div>
  );
};

export default FormInput;
