import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import whiteCallIcon from "../../assets/img/phoneIcon.svg";
import whiteWhatsappIcon from "../../assets/img/whatsappIcon.svg";
import whiteMailIcon from "../../assets/img/emailIcon.svg";
import placeholderUser from "../../assets/img/userDefaultImage.svg";
import { useSelector } from "react-redux";
import LoginModal from "../login/component/LoginModal";
import "./ApplicantCard.scss";
const ApplicantCard = ({ item, allWorkerList }) => {
  //   const { workers, allWorkerList } = props;
  //   const [loginPopUp, setLoginPopUp] = useState(false);
  //   const location = useLocation();
  //   const { isLoggedIn } = useSelector((state) => state.auth);
  const [phoneNum, setPhoneNum] = useState("");
  const [mailTo, setMailTo] = useState("");
  const [whatsAppTo, setWhatsAppTo] = useState("");
  // console.log("workers",workers);
  const navigate = useNavigate();
  const handleNavigate = () => {
    // if (isLoggedIn) {
    navigate(`/workers/profile/${item?.user_id}`);
    // } else {
    //   setLoginPopUp(true);
    // }
  };
  //   const handleContactNavigate = () => {
  //     if (isLoggedIn) {
  //       navigate(`/workers/profile/${workers?.id}`);
  //     } else {
  //       setLoginPopUp(true);
  //     }
  //   };

  useEffect(() => {
    if (item?.user?.phone) {
      setPhoneNum(`tel:${item?.user?.phone}`);
    }
    if (item?.user?.worker?.whatsapp) {
      setWhatsAppTo(
        `https://api.whatsapp.com/send?phone=${item?.user?.worker?.whatsapp}`
      );
    }
    if (item?.user?.email) {
      setMailTo(`mailto:${item?.user?.email}`);
    }
  }, [item]);

  const handleLink = (link, type) => {
    const alr = window.confirm(`Do you want to continue with ${type}`);
    if (alr) window.open(link, "_blank");
  };

  return (
    <div className={"col-inner col-inner-dashoard"}>
      <div className="profile-box-outer">
        <div className="profile-box">
          <img
            src={
              item?.user?.profile_img
                ? item?.user?.profile_img
                : placeholderUser
            }
            alt="Icon"
            style={{ width: "60px", height: "60px" }}
          />
          <div className="profile-head" onClick={() => handleNavigate()}>
            <h3 className="card-control-text-oneline">
              {item?.user?.first_name + " " + item?.user?.last_name}
            </h3>
            <span className="location card-control-text-oneline">
              {item?.user?.worker?.desire_country}
            </span>
          </div>
        </div>
        <div className="icon-box" style={{ cursor: "pointer" }}>
          {phoneNum && (
            <Link to={phoneNum} target="_blank">
              <div className="mail-icon">
                <img width={50} height={50} src={whiteCallIcon} alt="Icon" />
              </div>
            </Link>
          )}
          {mailTo && (
            <div className="" onClick={() => handleLink(mailTo, "mail")}>
              <div className="mail-icon">
                <img width={50} height={50} src={whiteMailIcon} alt="Icon" />
              </div>
            </div>
          )}
          {whatsAppTo && (
            <div
              className="whatsapp-icon"
              onClick={() => handleLink(whatsAppTo, "whatsapp")}
            >
              <img src={whiteWhatsappIcon} width={50} height={50} alt="Icon" />
            </div>
          )}
        </div>
      </div>
      {item?.user?.worker?.profession?.length > 0 && (
        <div className="skills-box">
          <h4>What i do</h4>
          <span className="skills">
            <ul>
              {/* {item?.profession.map((item, index) => ( */}
              <li>{item?.user?.worker?.profession.join()}</li>
              {/* ))} */}
            </ul>
          </span>
        </div>
      )}
      <div className="details">
        <p className="card-control-text-fourline">
          {item?.user?.profile?.about}
        </p>
      </div>
      <div className="box-outer">
        <div style={{ display: "flex", gap: "10px" }}>
          <div className="box">
            <h5>Experience</h5>
            {item?.experience ? (
              <span className="box-info">
                {item?.experience} {item?.experience == 1 ? "year" : "years"}
              </span>
            ) : (
              <span className="box-info">Fresher</span>
            )}
          </div>
          <div className="box">
            <h5>Preference</h5>
            <span className="box-info">{item?.user?.worker?.work_type}</span>
          </div>
          <div className="box">
            <h5>Profile</h5>
            <span className="box-info">
              {item?.user?.worker?.is_public ? "Public" : "Private"}
            </span>
          </div>
        </div>

        <div className="mt-3">
          {item?.user?.worker?.created_by === "independent" ? (
            <span
              className="profileType public p-2"
              style={{
                background: "rgba(0, 168, 67, 0.10)",
                borderRadius: "10px",
              }}
            >
              Independent
            </span>
          ) : (
            <span
              className="profileType private p-2"
              style={{
                background: "rgba(255, 68, 43, 0.10)",
                borderRadius: "10px",
              }}
            >
              Agency
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default ApplicantCard;
