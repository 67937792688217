import React from "react";
import { Spinner} from "reactstrap";

export default function Loader() {
  return (
   <div style={{
     position: "fixed",
    // width: "100%",
    // height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left:0,
    right:0,
    top:0,
    bottom:0,
    backgroundColor: "rgba(255,255,255,0.2)",
    zIndex:9999 ,
   }}>
    <Spinner
    color="info"
    style={{
      height: '3rem',
      width: '3rem'
    }}
  >
    Loading...
  </Spinner>
  </div>
  )
}