import { GET_JOB_LIST, GET_CATEGORY_LIST, GET_COUNTRY_LIST, GET_EXP_REGION, GET_PROFESSION_LIST, GET_RELIGION_LIST, GET_STATIC_DATA, GET_WORKER_LIST } from "../types";

const initialState ={
    staticData:[],
    workerList:[],
    experienceRegion:[],
    religionList:[],
    catergoryList:[],
    professionList:[],
    countryList:[],
    jobList : {
      totalCount : 0,
      jobList : []
    }
}
const dashboard = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_WORKER_LIST:
      return {
        ...state,
        workerList: payload,
      };
      case GET_STATIC_DATA:
        return{
            ...state,
            staticData:payload
        };
      case GET_EXP_REGION:
        return{
          ...state,
          experienceRegion:payload
        }
        case GET_RELIGION_LIST:
        return{
          ...state,
          religionList:payload
        }
        case GET_CATEGORY_LIST:
          return{
            ...state,
            catergoryList:payload
          }
        case GET_PROFESSION_LIST:
        return{
          ...state,
          professionList:payload
        }

        case GET_COUNTRY_LIST:
          return{
            ...state,
            countryList:payload
          }
        case GET_JOB_LIST:
          return {
            ...state,
            jobList : {jobList : payload.jobList, totalCount : payload.totalCount}
          }
      default:
        return state;
    }
}

export default dashboard