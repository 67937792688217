import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import PublishPaymentModal from "./PublishPaymentModal";

const PublishYourProfile = (props) => {
  const { modal, toggle, setPublishModal, paymentModal, setPaymentModal } =
    props;

  return (
    <Modal
      isOpen={modal}
      size="md"
      toggle={toggle}
      style={{ maxWidth: "876px" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-publish-gradient-box ">
        <ModalBody>
          <div className="gradient-box">
            <h2 className="w-100">
              Hura, your profile is now <br /> created in 'Private Mode'.
            </h2>
            <p>
              In <strong>'Private Mode'</strong>, profiles can be discovered by
              our scouts and recruiters. Activate <strong>'Public Mode'</strong>{" "}
              for 30 days to be discovered by everyone.
            </p>
          </div>
          <div className="text-center mt-4">
            <div className="btn btn-blue" onClick={() => setPaymentModal(true)}>
              Publish your Profile for 30 days
            </div>
            <p className="mt-3" onClick={() => toggle()}>
              Skip for now (Keep your profile private listed)
            </p>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default PublishYourProfile;
