import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    INIT_SUCCESS,
    UPDATE_USER_DATA,
    USER_AFTER_NP_LOGIN,
    INIT_WT_SUCCESS
  } from "../types"
  

  
  const initialState ={ 
    isLoggedIn: false, 
    user: null,
    navto: null
  }  //need to change after token expiry set
  
  const auth = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
      case REGISTER_SUCCESS:
    //    localStorage.setItem('user', JSON.stringify(action.payload.user))
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user.data
        }
      case REGISTER_FAIL:
        return {
          ...state,
          isLoggedIn: false,
        }
      case LOGIN_SUCCESS:
        // const { user } = action.payload
    //    localStorage.setItem('user', JSON.stringify(user))
        return {
          ...state,
          isLoggedIn: true,
          // user: payload.user,
        }
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        }

      case INIT_SUCCESS:
          return {
            ...state,
             isLoggedIn: true,
            user: payload.user,
          }
        case INIT_WT_SUCCESS:
          return {
            ...state,
             isLoggedIn: false,
            user: payload.user,
          }
      case USER_AFTER_NP_LOGIN:
        const isWorker = payload.user.data?.worker
        return {
          ...state,
          isLoggedIn: false,
          user: payload.user.data,
        }

      case UPDATE_USER_DATA:
          return {
            ...state,
            user: {...state.user, 
              data: {...state.user.data, first_name:action.payload.first_name, last_name:action.payload.last_name
            },
          },
          }
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        }


      default:
        return state
    }
  }
  export default auth