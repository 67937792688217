import React from "react";
import "./Input.scss";
import PhoneInput from "react-phone-input-2";
import callIcon from "../../assets/img/call.svg";
const MobileInput = ({
  label,
  containerClassName,
  inputClass,
  error,
  inputType,
  labelIcon,
  isDisable,
  ...otherProps
}) => {
  return (
    <div className={`${containerClassName}`}>
      <label
        htmlFor="exampleInputEmail1"
        className={label ? "form-label" : "d-none"}
      >
        {label}
        {labelIcon && <img src={labelIcon} alt="label-icon" className="ms-2" />}
      </label>
      <div className="d-flex form-control p-0 align-items-center">
        {/* {inputType==="page"?<></>: <img src={callIcon} alt="" className="px-2" />} */}
        <PhoneInput
          inputClass={`w-100 ps-5 pt-2 border-none ${inputClass}`}
          inputStyle={{
            border: "none",
          }}
          placeholder="Enter phone"
          buttonClass="bg-white"
          buttonStyle={{ border: "none" }}
          disabled={isDisable ? isDisable : false}
          containerClass=""
          countryCodeEditable={false}
          country={"us"}
          {...otherProps}
        />
      </div>
      {error && <label className="label-input100 text-danger">{error}</label>}
    </div>
  );
};

export default MobileInput;
