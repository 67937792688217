import { useDispatch, useSelector } from "react-redux";
import WorkerPoolUserProfile from "./WorkerPoolUserProfile";
import { useEffect, useState } from "react";
import { loadUser } from "../../store/auth/authActions";
import {
  getUserProfileById,
  getWPUserProfileById,
  updateNobelUserDetails,
  updateWorkerDetailsByAgency,
  updateWorkerUserDetails,
} from "../../store/profile/ProfileAction";
import axios from "axios";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  getAllCountries,
  getAllProfession,
  getAllReligion,
  getExperienceRegion,
} from "../../store/dashboard/dashboardAction";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { call } from "../../api/apiCall";
import { initLoad, stopLoad } from "../../store/loader/loaderActions";
import { useLocation, useSearchParams } from "react-router-dom";

export default function WorkerPoolUserProfileContainer() {
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const { nobelprofileData } = useSelector((state) => state.profile);
  const [isSeeMore, setIsSeeMore] = useState(true);
  const [addIntroModal, setAddIntroModal] = useState(false);
  const [profileField, setProfileField] = useState("profile");
  const [profilePhotoModal, setProfilePhotoModal] = useState(false);
  const [age, setAge] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorAbout, setErrorAbout] = useState("");
  const [errorHeadline, setErrorHeadline] = useState("");
  const [errorDob, setErrorDob] = useState("");
  const [errorExp, setErrorExp] = useState("");
  const [errorPrefCountry, setErrorPrefCountry] = useState("");
  const [errorNationality, setErrorNationality] = useState("");
  const [errorROE, setErrorROE] = useState("");
  const [errorJobType, setErrorJobType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [errorWhatsappNumber, setErrorWhatsappNumber] = useState("");
  const [countinue, setCountinue] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [errorCategory, setErrorCategory] = useState("");
  const [errorProfession, setErrorProfession] = useState("");
  const [activePage, setActivePage] = useState("");
  const [errorGender, setErrorGender] = useState("");

  const [phoneCount, setPhoneCount] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();

  const [publishModal, setPublishModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const [agency, setAgency] = useState(0);
  const [worker, setWorker] = useState(null);

  const {
    experienceRegion,
    religionList,
    professionList,
    catergoryList,
    countryList,
  } = useSelector((state) => state.dashboard);

  const [basicForm, setBasicForm] = useState(
    agency && worker
      ? {
          first_name: worker?.user?.first_name,
          last_name: worker?.user?.last_name,
          about: worker?.user?.profile?.about,
          profile_headline: worker?.user?.profile?.profile_headline,
          email: worker?.email,
          dob: new Date(worker?.user?.profile?.dob),
          experience: worker?.experience,
          phone: worker?.user?.phone,
          whatsapp: worker?.whatsapp,
          desire_country: worker?.desire_country,
          nationality: worker?.user?.profile?.nationality?.id,
          experience_region: worker?.experienceRegion?.id,
          work_type: worker?.work_type,
          religion: worker?.religion,
          profession: worker?.profession,
          gender: worker?.user?.profile?.gender,
        }
      : nobelprofileData
      ? {
          first_name: nobelprofileData?.user?.first_name,
          last_name: nobelprofileData?.user?.last_name,
          about: nobelprofileData?.user?.profile?.about,
          profile_headline: nobelprofileData?.user?.profile?.profile_headline,
          email: nobelprofileData?.email,
          dob: new Date(nobelprofileData?.user?.profile?.dob),
          experience: nobelprofileData?.experience,
          phone: nobelprofileData?.user?.phone,
          whatsapp: nobelprofileData?.whatsapp,
          desire_country: nobelprofileData?.desire_country,
          nationality: nobelprofileData?.user?.profile?.nationality?.id,
          experience_region: nobelprofileData?.experienceRegion?.id,
          work_type: nobelprofileData?.work_type,
          religion: nobelprofileData?.religion,
          profession: nobelprofileData?.profession[0]?.profession,
          gender: nobelprofileData?.user?.profile?.gender,
        }
      : {
          first_name: "",
          last_name: "",
          about: "",
          profile_headline: "",
          dob: "",
          experience: 0,
          phone: "",
          whatsapp: "",
          desire_country: "",
          nationality: "",
          profession: [],
          experience_region: "",
          work_type: "",
          religion: "",
          gender: "",
        }
  );
  // const location = useLocation();

  console.log(worker, nobelprofileData);
  const [searchParams, setSearchParams] = useSearchParams();

  var today = new Date();

  useEffect(() => {
    getRegionOfExperience();
    getNationality();
    getReligion();
    // const queryParams = new URLSearchParams(location?.search);
    if (searchParams?.get("type")) {
      setProfileField(searchParams?.get("type"));
      setSearchParams({});
    }
  }, []);

  // const emailExist = (emailName) => {
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       try {
  //         const res = await call(
  //           "get",
  //           `api/v1/Auth/email-exist/${emailName}`,
  //           null,
  //           null,
  //           null
  //         );
  //         resolve(true);
  //       } catch (err) {
  //         resolve(false);
  //       }
  //     })();
  //   });
  // };

  const phoneExist = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/auth/phone-exist/${phoneNumber}`,
            null,
            null,
            null
          );
          resolve(true);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };

  // const [emailexistence, setEmailExistence] = useState(true);
  const [phoneExistence, setPhoneExistence] = useState(true);
  const checkSignUpValidation = (type) => {
    let stringName = /^[a-zA-Z\s._]+$/;
    let stringNameLast = /^[a-zA-Z\s._]+$/;
    let formIsValid = true;
    if (type === "all" || type === "firstName") {
      if (!basicForm.first_name) {
        setErrorFirstName("Enter your first name.");
        formIsValid = false;
        return;
      } else if (!stringName.test(basicForm.first_name)) {
        setErrorFirstName("First name should be characters only.");
        formIsValid = false;
        return;
      } else if (basicForm.first_name.length > 50) {
        setErrorFirstName("Max 50 characters allowed.");
        formIsValid = false;
        return;
      } else {
        setErrorFirstName("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "lastName") {
      if (!basicForm.last_name) {
        setErrorLastName("Enter your last name.");
        formIsValid = false;
        return;
      } else if (!stringNameLast.test(basicForm.last_name)) {
        setErrorLastName("Last name is invalid.");
        formIsValid = false;
        return;
      } else if (basicForm.last_name.length > 50) {
        setErrorLastName("Max 50 characters allowed.");
        formIsValid = false;
        return;
      } else {
        setErrorLastName("");
        formIsValid = true;
      }
    }
    if ((type = "all" || type === "about")) {
      if (!basicForm?.about) {
        setErrorAbout("Please add about yourself");
        formIsValid = false;
        return;
      } else {
        setErrorAbout("");
        formIsValid = true;
      }
    }
    if ((type = "all" || type === "headline")) {
      if (!basicForm?.profile_headline) {
        setErrorHeadline("Please add headline");
        formIsValid = false;
        return;
      } else {
        setErrorHeadline("");
        formIsValid = true;
      }
    }
    if (type == "all" || type == "gender") {
      if (!basicForm?.gender) {
        setErrorGender("Please add Gender");
        formIsValid = false;
        return;
      } else {
        setErrorGender("");
        formIsValid = true;
      }
    }
    if ((type = "all" || type === "dob")) {
      if (!basicForm?.dob) {
        setErrorDob("Please add Date of Birth");
        formIsValid = false;
        return;
      } else {
        setErrorDob("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "exp") {
      if (basicForm?.experience < 0) {
        setErrorExp("Please add experience ");
        formIsValid = false;
        return;
      } else if (
        new Date().getFullYear() -
          new Date(basicForm?.dob).getFullYear() -
          17 <=
        basicForm?.experience
      ) {
        setErrorExp("Invalid experience as based on date of birth");
        formIsValid = false;
        return;
      } else {
        setErrorExp("");
        formIsValid = true;
      }
    }

    if ((type = "all" || type === "prefCountry")) {
      if (!basicForm?.desire_country) {
        setErrorPrefCountry("Please add preferred Country");
        formIsValid = false;
        return;
      } else {
        setErrorPrefCountry("");
        formIsValid = true;
      }
    }
    if ((type = "all" || type === "nationality")) {
      if (!basicForm?.nationality) {
        setErrorNationality("Please add Nationality");
        formIsValid = false;
        return;
      } else {
        setErrorNationality("");
        formIsValid = true;
      }
    }
    if ((type = "all" || type === "roe")) {
      if (!basicForm?.experience_region) {
        setErrorROE("Please add region of experience");
        formIsValid = false;
        return;
      } else {
        setErrorROE("");
        formIsValid = true;
      }
    }
    if ((type = "all" || type === "jobType")) {
      if (!basicForm?.work_type) {
        setErrorJobType("Please add preferred Job Type");
        formIsValid = false;
        return;
      } else {
        setErrorJobType("");
        formIsValid = true;
      }
    }
    if ((type = "all" || type === "category")) {
      if (!selectedCategory) {
        setErrorCategory("Please add Category");
        formIsValid = false;
        return;
      } else {
        setErrorCategory("");
        formIsValid = true;
      }
    }
    {
      console.log("basicForm?.profession", basicForm?.profession.length);
    }
    if ((type = "all" || type === "profession")) {
      if (selectedCategory && !basicForm?.profession) {
        setErrorProfession("Please add professions for this category");
        formIsValid = false;
        return;
      } else if (basicForm?.profession?.length > 5) {
        setErrorProfession("You can select upto 5 professions only");
        formIsValid = false;
        return;
      } else {
        setErrorProfession("");
        formIsValid = true;
      }
    }
    if (type === "all" || type === "phoneNumber") {
      if (basicForm?.phone?.length == 0) {
        setErrorPhoneNumber("Please enter the phone number.");
        formIsValid = false;
        return;
      } else if (basicForm?.phone?.length < 10) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      } else if (!phoneCountValidation(basicForm.phone)) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      }
      // else if (basicForm?.phone) {
      //   phoneExist(basicForm?.phone).then((response) => {
      //     console.log("loffff", response);
      //     if (response === false) {
      //       setErrorPhoneNumber("Phone already exist.");
      //       formIsValid = false;
      //       // setPhoneExistence(false);
      //       return
      //     } else {
      //       setErrorPhoneNumber("");
      //       // setPhoneExistence(true);
      //     }
      //   });
      // }
      else {
        setErrorPhoneNumber("");
        formIsValid = true;
      }
    }

    if (type === "all" || type === "whatsappNumber") {
      if (basicForm.whatsapp.length === 0) {
        setErrorWhatsappNumber("Please enter the phone number.");
        formIsValid = false;
        return;
      } else if (basicForm.whatsapp.length < 10) {
        setErrorWhatsappNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      } else if (!phoneCountValidation(basicForm.whatsapp)) {
        setErrorWhatsappNumber("Please enter the valid number.");
        formIsValid = false;
        return;
      }
      // else if (phoneNumber) {
      //   phoneExist(phoneNumber).then((response) => {
      //     if (response === false) {
      //       setErrorPhoneNumber("Phone already exist.");
      //       formIsValid = false;
      //       setPhoneExistence(false);
      //     } else {
      //       setErrorPhoneNumber("");
      //       setPhoneExistence(true);
      //     }
      //   });
      // }
      else {
        setErrorWhatsappNumber("");
        formIsValid = true;
      }
    }
    return formIsValid;
  };

  const phoneCountValidation = (mobileNumber) => {
    let num = JSON.stringify(mobileNumber);
    let code = JSON.stringify(phoneCount);
    const number = phoneUtil?.parse(num, code);

    //console.log(phoneUtil.isValidNumber(number));
    return phoneUtil?.isValidNumber(number);
  };
  useEffect(() => {
    getProfession();
  }, [selectedCategory]);

  const handleFormSubmit = () => {
    const newForm = delete basicForm["email"];
    console.log(basicForm?.profession);
    if (worker)
      dispatch(updateWorkerDetailsByAgency(basicForm, token, worker?.id))
        .then((res) => {
          if (res.success) {
            setActivePage("");
            toast.success("Worker updated successfuly", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else return;
        })
        .catch((e) => {
          let message =
            (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString();
          setErrorMessage(message);
          return;
        });
    else
      dispatch(updateWorkerUserDetails(basicForm, token, nobelprofileData?.id))
        .then((res) => {
          if (res.success) {
            setProfileField("profile");
            toast.success("Profile updated successfuly", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          } else return;
        })
        .catch((e) => {
          let message =
            (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString();
          setErrorMessage(message);
          return;
        });
  };

  const submitSignUpData = () => {
    setErrorMessage("");
    if (checkSignUpValidation("all")) {
      handleFormSubmit();
    }
  };

  const getRegionOfExperience = () => {
    dispatch(getExperienceRegion());
  };

  const getNationality = () => {
    dispatch(getAllCountries());
  };
  const getReligion = () => {
    dispatch(getAllReligion());
  };
  const getCategory = () => {
    dispatch(getAllProfession());
  };
  const getProfession = () => {
    dispatch(
      getAllProfession({ category: selectedCategory ? selectedCategory : null })
    );
  };

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function remainingTime(dateString) {
    var today = new Date();
    var publishDate = new Date(dateString);
    const diffTime = Math.abs(publishDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // var timeLeft = 30 -diffDays;
    // console.log("timeLeft",diffDays);
    return diffDays;
  }
  useEffect(() => {
    getCategory();
    if (isLoggedIn) {
      dispatch(loadUser());
    }
  }, []);
  useEffect(() => {
    // dispatch(getUserProfileById(user?.id, token));
    if (user?.about?.length > 400) {
      setIsSeeMore(true);
    }
    dispatch(getWPUserProfileById(user?.worker?.id, token));
  }, [user]);
  useEffect(() => {
    if (nobelprofileData?.user?.profile?.intro_video) {
      setVideoUrl(nobelprofileData?.user?.profile?.intro_video);
    }
    if (!nobelprofileData?.user?.profile?.intro_video) {
      setVideoUrl("");
    }
    setAge(getAge(nobelprofileData?.user?.profile?.dob));

    setBasicForm({
      first_name: nobelprofileData?.user?.first_name,
      last_name: nobelprofileData?.user?.last_name,
      about: nobelprofileData?.user?.profile?.about,
      profile_headline: nobelprofileData?.user?.profile?.profile_headline,
      email: nobelprofileData?.email,
      dob: new Date(nobelprofileData?.user?.profile?.dob),
      experience: nobelprofileData?.experience,
      phone: nobelprofileData?.user?.phone,
      whatsapp: nobelprofileData?.whatsapp,
      desire_country: nobelprofileData?.desire_country,
      nationality: nobelprofileData?.user?.profile?.nationality?.id,
      experience_region: nobelprofileData?.experienceRegion?.id,
      work_type: nobelprofileData?.work_type,
      religion: nobelprofileData?.religion,
      profession: nobelprofileData?.profession?.map((ele) => ele?.profession),
      gender: nobelprofileData?.user?.profile?.gender,
      // category:nobelprofileData?.profession?.category
    });
    setSelectedCategory(nobelprofileData?.profession[0]?.category);
  }, [nobelprofileData]);

  useEffect(() => {
    if (agency && worker) {
      if (worker?.user?.profile?.intro_video) {
        setVideoUrl(worker?.user?.profile?.intro_video);
      }
      if (!worker?.user?.profile?.intro_video) {
        setVideoUrl("");
      }
      setAge(getAge(worker?.user?.profile?.dob));

      setBasicForm({
        first_name: worker?.user?.first_name,
        last_name: worker?.user?.last_name,
        about: worker?.user?.profile?.about,
        profile_headline: worker?.user?.profile?.profile_headline,
        email: worker?.email,
        dob: new Date(worker?.user?.profile?.dob),
        experience: worker?.experience,
        phone: worker?.user?.phone,
        whatsapp: worker?.whatsapp,
        desire_country: worker?.desire_country,
        nationality: worker?.user?.profile?.nationality?.id,
        experience_region: worker?.experienceRegion?.id,
        work_type: worker?.work_type,
        religion: worker?.religion,
        profession: worker?.profession,
        gender: worker?.user?.profile?.gender,
        // category:worker?.profession?.category
      });
      setSelectedCategory(worker?.profession[0]?.category);
    }
  }, [worker]);

  const toggleSeeMore = () => {
    setIsSeeMore(!isSeeMore);
  };

  const handleRemovePicture = (type) => {
    // if (type === "profile") {
    let formatProfileData = {
      profile_img: "",
    };
    dispatch(updateNobelUserDetails(formatProfileData, token));
  };

  const handleBannerPicture = async (file) => {
    const newImage = new File([file], "newEventCropped", { type: "image/png" });

    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };
      formData.append("image", newImage);
      dispatch(initLoad());
      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        console.log("uploading", res);
        dispatch(stopLoad());
        let formatProfileData = {
          profile_img: res?.data?.urlsArray[0],
        };
        dispatch(initLoad());
        dispatch(updateNobelUserDetails(formatProfileData, token)).then(
          (response) => {
            console.log("updating profile", response);
            dispatch(stopLoad());
            setProfilePhotoModal(false);
          }
        );
      });
    } catch (err) {
      dispatch(stopLoad());
      console.log(err);
    }
  };
  const handleProfession = (e) => {
    // var options = e.target.options;
    // var value = [];
    // for (var i = 0, l = options.length; i < l; i++) {
    //   if (options[i].selected) {
    //     value.push(options[i].value);
    //   }
    // }

    let name = e.map((ele) => ele?.value);
    setBasicForm({
      ...basicForm,
      profession: [...name],
    });
    setErrorProfession("");
  };

  const [selectJob, setSelectedJob] = useState(0);

  return (
    <WorkerPoolUserProfile
      user={user}
      isSeeMore={isSeeMore}
      toggleSeeMore={toggleSeeMore}
      nobelprofileData={nobelprofileData}
      addIntroModal={addIntroModal}
      setAddIntroModal={setAddIntroModal}
      videoUrl={videoUrl}
      age={age}
      profilePhotoModal={profilePhotoModal}
      setProfilePhotoModal={setProfilePhotoModal}
      handleRemovePicture={handleRemovePicture}
      handleBannerPicture={handleBannerPicture}
      profileField={profileField}
      setProfileField={setProfileField}
      submitSignUpData={submitSignUpData}
      handleFormSubmit={handleFormSubmit}
      checkSignUpValidation={checkSignUpValidation}
      errorEmail={errorEmail}
      errorPassword={errorPassword}
      setErrorPassword={setErrorPassword}
      setErrorEmail={setErrorEmail}
      errorFirstName={errorFirstName}
      setErrorFirstName={setErrorFirstName}
      errorLastName={errorLastName}
      setErrorLastName={setErrorLastName}
      errorPhoneNumber={errorPhoneNumber}
      errorMessage={errorMessage}
      setPhoneCount={setPhoneCount}
      basicForm={basicForm}
      setBasicForm={setBasicForm}
      today={today}
      countryList={countryList}
      religionList={religionList}
      experienceRegion={experienceRegion}
      errorWhatsappNumber={errorWhatsappNumber}
      errorAbout={errorAbout}
      setErrorAbout={setErrorAbout}
      errorHeadline={errorHeadline}
      setErrorHeadline={setErrorHeadline}
      errorDob={errorDob}
      setErrorDob={setErrorDob}
      errorExp={errorExp}
      setErrorExp={setErrorExp}
      errorPrefCountry={errorPrefCountry}
      setErrorPrefCountry={setErrorPrefCountry}
      errorNationality={errorNationality}
      setErrorNationality={setErrorNationality}
      errorROE={errorROE}
      setErrorROE={setErrorROE}
      errorJobType={errorJobType}
      setErrorJobType={setErrorJobType}
      getRegionOfExperience={getRegionOfExperience}
      setCountinue={setCountinue}
      publishModal={publishModal}
      setPublishModal={setPublishModal}
      remainingTime={remainingTime}
      paymentModal={paymentModal}
      setPaymentModal={setPaymentModal}
      catergoryList={catergoryList}
      professionList={professionList}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      errorCategory={errorCategory}
      setErrorCategory={setErrorCategory}
      handleProfession={handleProfession}
      errorProfession={errorProfession}
      setErrorProfession={setErrorProfession}
      selectJob={selectJob}
      setSelectedJob={setSelectedJob}
      setAgency={setAgency}
      agency={agency}
      worker={worker}
      setWorker={setWorker}
      activePage={activePage}
      setActivePage={setActivePage}
      errorGender={errorGender}
      setErrorGender={setErrorGender}
    />
  );
}
