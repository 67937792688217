import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import FormInput from "../../../core/Inputs/FormInput";
import mailIcon from "../../../assets/img/mailIcon.png";
import shieldSecurity from "../../../assets/img/shieldSecurity.png";
import { useDispatch, useSelector } from "react-redux";
import { authApi, setSession } from "../../../api/authAPI";
import {
  getUserAfterNPLogin,
  loadUser,
  loginOnNP,
  providerRegister,
  seekerRegister,
} from "../../../store/auth/authActions";
import { LOGIN_SUCCESS } from "../../../store/types";
import { toast } from "react-toastify";

const LoginWithNP = (props) => {
  let { modal, toggle, type, isLogin } = props;
  type = type || "worker";
  const [emailName, setEmailName] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [genrateToken, setGenrateToken] = useState();
  const { user } = useSelector((state) => state.auth);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  // const { isLoggedIn } = useSelector((state) => state.auth);
  const checkValidation = (type) => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let formIsValid = true;

    if (type === "emailName" || type === "all") {
      if (!emailName) {
        setErrorEmail("Please enter your email.");
        formIsValid = false;
        return;
      } else if (!emailvalid.test(emailName)) {
        setErrorEmail("Please enter your email in a valid format.");
        formIsValid = false;
        return;
      } else {
        setErrorEmail("");
      }
    }
    if (type === "password" || type === "all") {
      if (!password) {
        setErrorPassword("Please enter your password.");
        formIsValid = false;
      } else if (!passwordValid.test(password)) {
        setErrorPassword(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
        return;
      } else {
        setErrorPassword("");
      }
    }
    return formIsValid;
  };

  const handleFormSubmit = async () => {
    let registration_type = "email";
    dispatch(loginOnNP(emailName, password, registration_type))
      .then((res) => {
        if (res?.success)
          dispatch(getUserAfterNPLogin(res.token)).then((response) => {
            if (response.success) {
              if (isLogin == "true") {
                if (!response?.data?.worker) {
                  // alert("You are not authorized");
                  const conf = window.confirm(
                    "Do you want to sync your data from your nobel page account?"
                  );
                  navigate("/worker/basic-info", {
                    state: { fromNP: true, loginAsNPUser: true, allowNp: conf },
                  });
                } else {
                  setSession(res.token);
                  dispatch(loadUser());
                  navigate("/");
                  dispatch({
                    type: LOGIN_SUCCESS,
                  });
                }
              } else {
                if (type == "worker") {
                  const conf = window.confirm(
                    "Do you want to sync your data from your nobel page account?"
                  );
                  navigate("/worker/basic-info", {
                    state: { fromNP: true, loginAsNPUser: true, allowNp: conf },
                  });
                } else if (type == "provider") {
                  if (response?.data?.worker) {
                    toast.error(
                      `You already have an account as ${response?.data?.worker.type}`
                    );
                  } else if (response?.data?.membership.id < 3) {
                    toast.error(`You need to have nobel+ or scout membership`);
                  } else {
                    dispatch(providerRegister(type, res.token));
                    setSession(res.token);
                    dispatch(loadUser());
                    navigate("/");
                    dispatch({
                      type: LOGIN_SUCCESS,
                    });
                  }
                } else {
                  if (response?.data?.worker) {
                    toast.error(
                      `You already have an account as ${response?.data?.worker.type}`
                    );
                  } else {
                    dispatch(providerRegister(type, res.token));
                    setSession(res.token);
                    dispatch(loadUser());
                    navigate("/");
                    dispatch({
                      type: LOGIN_SUCCESS,
                    });
                  }
                }
              }
            } else return;
            // if (response.success) {
            //   if(type == "worker"){
            //     if (response?.data?.worker) {
            //       setSession(res.token);
            //       dispatch(loadUser());
            //       navigate("/");
            //       dispatch({
            //         type: LOGIN_SUCCESS,
            //       });
            //     } else
            //       navigate("/worker/basic-info", {
            //         state: { fromNP: true, loginAsNPUser: true },
            //       });
            //   }else if(type == "provider"){
            //     if(response?.data?.worker){
            //       alert(`You already have an account as ${response?.data?.worker.type}`)
            //     }else if(response?.data?.membership.id < 3){
            //       alert(`You need to have nobel+ or scout membership`)
            //     }else{
            //       dispatch(providerRegister(type, res.token))
            //       navigate("/");
            //     }
            //   }

            // } else return;
          });
        else return;
        // navigate("/worker/basic-info", {
        //   state: { fromNP: true, loginAsNPUser: true },
        // });
        // if (res?.success) {
        //   setGenrateToken(res.token)
        //   dispatch(getUserAfterNPLogin(res.token));
        // }
      })
      .catch((e) => {
        let message =
          (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString();
        setErrorMessage(message);
        return;
      });
  };

  const submitLoginData = (e) => {
    e.preventDefault();

    if (checkValidation("all")) {
      handleFormSubmit();
    }
  };

  // useEffect(() => {
  //     if(user?.worker){
  //       navigate("/");
  //       setSession(genrateToken)
  //       dispatch(loadUser());
  //       // dispatch({
  //       //   type: LOGIN_SUCCESS})
  //     }
  //     else
  //     navigate("/worker/basic-info", {
  //       state: { fromNP: true,loginAsNPUser: true},
  //     })
  // }, [user])
  const ref = useRef();

  const redirectToNp = () => {
    alert(
      "You have to create an account in the NobelPage first then only you can login here in the WorkerPool with NobelPage page credentials"
    );
    window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/signup`);
  };

  return (
    <>
      <div className="login-popup-modal">
        <Modal
          toggle={toggle}
          // onBlur={toggle}
          isOpen={modal}
          size="md"
          centered
          backdrop={true}
          // className="profilePermission rounded-2"
        >
          {/* <ModalHeader className="" toggle={toggle}>
        </ModalHeader> */}

          <ModalBody className="bg-transparent">
            <div className="continue-as-header p-3">
              <h5>Login with NobelPage</h5>
            </div>
            <form className="mt-3">
              {errorMessage && (
                <label className="label-input100 text-danger">
                  {errorMessage}
                </label>
              )}
              <FormInput
                label="Email"
                icon={mailIcon}
                type="email"
                onChange={(e) => {
                  setEmailName(e.target.value);
                  setErrorEmail("");
                }}
                placeholder="Enter email"
                onBlur={() => checkValidation("emailName")}
                error={errorEmail}
              />
              <div className="mb-3"></div>
              <FormInput
                label="Password"
                icon={shieldSecurity}
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorPassword("");
                }}
                placeholder="Enter password"
                onBlur={() => checkValidation("password")}
                error={errorPassword}
              />
              {/* <div className="keep-logged">
                {console.log(rememberMe)}
                <label htmlFor="checkbox">
                  <input
                    type="checkbox"
                    id="checkbox"
                    onChange={() => console.log(reme)}
                    checked={rememberMe}
                  />
                  Keep me logged in
                  <span className="checkmark"></span>
                </label>
              </div> */}
              <button
                type="submit"
                className="login-button"
                onClick={(e) => submitLoginData(e)}
              >
                Login with NobelPage
              </button>
              <div className="terms-box">
                <p>
                  By clicking &quot;Login with NobelPage&quot;, you agree to
                  NobelPage&apos;s{" "}
                  <span
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/legal`
                      )
                    }
                  >
                    Legal Terms
                  </span>{" "}
                  including{" "}
                  <span
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/legal`
                      )
                    }
                  >
                    Terms of Use,
                  </span>{" "}
                  <span
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/legal`
                      )
                    }
                  >
                    Privacy Policy,
                  </span>{" "}
                  <span
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/legal`
                      )
                    }
                  >
                    Cookie Policy{" "}
                  </span>{" "}
                  and
                  <span
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/community`
                      )
                    }
                  >
                    {" "}
                    Community Guidelines,{" "}
                  </span>{" "}
                  <span
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/legal`
                      )
                    }
                  >
                    {" "}
                    Terms and Conditions
                  </span>
                  {" & "}
                  <span
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_STAGE_URL}/legal`
                      )
                    }
                  >
                    {" "}
                    Privacy Policy{" "}
                  </span>
                </p>
              </div>
              <span className="create-account mb-3">
                Don’t have an account?{" "}
                <span
                  onClick={redirectToNp}
                  style={{
                    color: "#19BCF4",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  {/* <Link target="_blank"> */}
                  Create Account
                  {/* </Link> */}
                </span>
              </span>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default LoginWithNP;
