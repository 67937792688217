import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { loadUserWTLogin, register } from "../../../store/auth/authActions";

import {
  getAllCountries,
  getAllProfession,
  getAllReligion,
  getExperienceRegion,
} from "../../../store/dashboard/dashboardAction";

import AddSeeker from "./AddSeeker";
import VerifyAccount from "../VerifyAccount/VerifyAccount";
import "./AddSeeker.scss"
import SeekerBasicInfo from "../SeekerBasicInfo/SeekerBasicInfo";
import AccountVerifySuccessModel from "../../AccountVerifyModel/AccountVerifySuccessModel";
export default function AddSeekerContainer() {
  const { user } = useSelector((state) => state.auth);
  

  const [pageNo, setPageNo] = useState(0)
  const dispatch = useDispatch();


  // const  company_id = location?.state ? parseInt(location?.state?.id) : null;

  const {
    
    countryList,
  } = useSelector((state) => state.dashboard);
  // const getUser = () => {
  //   dispatch(loadUserWTLogin());
  //   getNationality()
  // };
  


  
  const getNationality = () => {
    dispatch(getAllCountries());
  };
 


  useEffect(()=>{
    getNationality()
  },[])






  








  if (pageNo == 1) {
    return (
      <VerifyAccount
        user={user}
        // setCountinue={setCountinue}
        // isLoggedIn={isLoggedIn}
        // token={token}
        pageNo={pageNo}
        setPageNo={setPageNo}
      />
    );
  }
  if (pageNo == 2) {
    return (
      <AccountVerifySuccessModel
        setPageNo={setPageNo}
      />
    );
  }
  if (pageNo == 3) {
    return <SeekerBasicInfo user={user} setPageNo={setPageNo} 
    countryList={countryList}
    
    />;
  }
  
  return (
    <AddSeeker
      pageNo={pageNo}
      setPageNo={setPageNo}
      
    />
  );
}
