import React, { useEffect, useState } from "react";
import "./ChooseAccountType.scss";
import workerDummy1 from "../../assets/img/workerIcon.svg";
import workerDummy2 from "../../assets/img/providerIcon.svg";
import workerDummy3 from "../../assets/img/seekerIcon.svg";
import { Button } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderLogo from "../Header/header";

const ChooseAccountType = () => {
  const [selectedAs, setSelectedAs] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const handleContinue = () => {
    if (selectedAs === "worker") {
      navigate("/worker/basic-info");
    } else if (selectedAs === "provider") {
      navigate("/worker/create-provider");
    } else navigate("/worker/create-seeker");
  };

  return (
    <>
      <HeaderLogo />

      <section className="account-type-section">
        <div className="container">
          <div className="account-type-inner m-auto">
            <h1>Choose Account Type</h1>

            <ul>
              <li className="text-white" style={{ fontSize: "12px" }}>
                'I am a Worker' for job seekers,
                <li className="text-white" style={{ fontSize: "12px" }}>
                  {" "}
                  'I Provide Workers' for staffing agencies or companies,
                </li>
                <li className="text-white" style={{ fontSize: "12px" }}>
                  {" "}
                  'I Looking for Workers' for individuals, companies, or
                  recruiters seeking workers
                </li>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="three-col-box-section">
        <div className="container">
          <div className="three-col-box-inner m-auto">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 icon-col">
                <div
                  className={
                    "col-outer " +
                    (selectedAs === "worker" ? "active-border" : "")
                  }
                  onClick={() => setSelectedAs("worker")}
                >
                  <span className="icon">
                    <img src={workerDummy1} alt="Icon" />
                  </span>
                  <h3>I am a Worker</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 icon-col">
                <div
                  className={
                    "col-outer " +
                    (selectedAs === "provider" ? "active-border" : "")
                  }
                  onClick={() => setSelectedAs("provider")}
                >
                  <span className="icon">
                    <img src={workerDummy2} alt="Icon" />
                  </span>
                  <h3>I Provide Workers</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 icon-col">
                <div
                  className={
                    "col-outer " +
                    (selectedAs === "search-workers" ? "active-border" : "")
                  }
                  onClick={() => setSelectedAs("search-workers")}
                >
                  <span className="icon">
                    <img src={workerDummy3} alt="Icon" />
                  </span>
                  <h3>I am looking for Workers</h3>
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                className={
                  "btn continue-btn" + (selectedAs ? "" : " disable-btn")
                }
                // disabled={isDisabled}
                // disabled={isDisabled}
                onClick={() => handleContinue()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChooseAccountType;
