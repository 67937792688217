import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "../WorkerPoolDefaultForm.scss";
import edit from "../../../assets/img/edit.png";
import buld from "../../../assets/img/circle-building.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addexperiencedetails,
  getLocationsData,
  getNobelUserProfile,
} from "../../../store/profile/ProfileAction";
import { call } from "../../../api/apiCall";
import Autocomplete from "react-autocomplete";
import {
  getAllCountries,
  getAllProfession,
  getAllReligion,
} from "../../../store/dashboard/dashboardAction";
import DatePickerCustom from "../../../core/DatePicker/DatePickerReact";
import JobPreview from "../JobPreview/JobPreview";
import { jobApi } from "../../../api/jobApi";
import HeaderLogo from "../../Header/header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const AddJobDetails = () => {
  const { catergoryList, professionList, countryList, religionList } =
    useSelector((state) => state.dashboard);

  const { user } = useSelector((state) => state.auth);

  const { nobelprofileData } = useSelector((state) => state.profile);
  const titleRef = useRef();
  const [editExperienceData, setEditExperienceData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [profession, setProfession] = useState("");
  const [con, setCon] = useState(0);
  let [expData, setExpData] = useState({
    title: "",
    employment_type: "",
    company_name: "",
    salary_type: "",
    gender: "",
    religion: "",
    profession: "",
    nationality: [],
    job_location: "",
    min_experience: 0,
    max_experience: 0,
    min_salary: 0,
    max_salary: 0,
    age: 0,
    contact_detail: false,
    wp_job: true,
    account_type: null,
    company_id: null,
  });

  const [category, setCategory] = useState("");
  // const [errorTitle, setErrorTitle] = useState("");
  // const [errorCompany, setErrorCompany] = useState("");
  // const [errorDate, setErrorDate] = useState("");
  // const [companyType, setCompanyType] = useState("");

  const [error, setError] = useState({
    title: "",
    employment_type: "",
    company_name: "",
    salary_type: "",
    gender: "",
    profession: "",
    nationality: "",
    job_location: "",
    min_experience: "",
    max_experience: "",
    min_salary: "",
    max_salary: "",
    age: "",
    account_type: "",
  });

  const navigate = useNavigate();

  const [textLength, setTextLength] = useState(
    editExperienceData?.description
      ? editExperienceData.description.split(/\+/g).join("").length
      : 0
  );
  const [showLabel, setShowLabel] = useState("");
  const [locationInput, setLocationInput] = useState(
    editExperienceData?.location ? editExperienceData?.location : ""
  );
  const [locationList, setLocationList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [employmentTypeList, setEmploymentTypeList] = useState();
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [currencyCodes, setCurrencyCodes] = useState({});

  const currencySymbol = async () => {
    try {
      const res = await axios.get(
        `https://api.exchangerate-api.com/v4/latest/USD`
      );
      if (res?.status == 200) {
        setCurrencyCodes(res?.data?.rates);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(expData);
  useEffect(() => {
    if (locationInput) {
      dispatch(getLocationsData(locationInput))
        .then((res) => {
          if (res?.data) {
            setLocationList(res?.data);
          } else setLocationList([]);
        })
        .catch(() => {
          setLocationList([]);
        });
    }
  }, [locationInput]);
  // useEffect(() => {
  //   let id = user?.id;
  //   if (id) {
  //     dispatch(getNobelUserProfile(id, token)).then((response) => {
  //       console.log(response.success);
  //       if (response.success === false) return;
  //     });
  //   }
  // }, [user]);
  // useEffect(() => {
  //   console.log("edit exp", editExperienceData);
  //   if (editExperienceData)
  //     setExpData({
  //       id: editExperienceData?.id,
  //       title: editExperienceData?.title,
  //       employment_type: editExperienceData?.employment_type,
  //       company_name: editExperienceData?.company_name,
  //       location: editExperienceData?.location,
  //       start_date: moment(editExperienceData?.start_date)
  //         .utc()
  //         .format("YYYY-MM-DD"),
  //       end_date: editExperienceData?.end_date
  //         ? moment(editExperienceData?.end_date).utc().format("YYYY-MM-DD")
  //         : editExperienceData?.end_date,
  //       description: editExperienceData?.description,
  //       is_current: editExperienceData?.is_current,
  //       is_visible: editExperienceData?.is_visible,
  //       notify_network: false,
  //       country_id: "",
  //       state_id: "",
  //       city_id: "",
  //     });
  // }, [editExperienceData]);

  const getCompanyList = (value) => {
    (async () => {
      try {
        const res = await call(
          "get",
          `api/v1/public/page-list?search=${value}`,
          null,
          null
        );
        console.log(res, "fghfghgffgrhfghfgrh");
        if (res?.data?.success) {
          // console.log(res.data);
          setCompanyList(res?.data?.data);
          console.log(res.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  };

  const getLocation = (e) => {
    console.log(e);
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/location?search=${e}`,
            null,
            null,
            null
          );
          if (res.data.success) {
            setLocationList(res?.data?.data);
          }
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };

  useEffect(() => {
    setShowLabel(expData.employment_type);
    getCategory();
    currencySymbol();

    if (user?.worker?.type !== "provider") navigate("/");
  }, []);

  useEffect(() => {
    if (category) dispatch(getAllProfession({ category }));
  }, [category]);

  const checkValidation = (type) => {
    let formIsValid = true;
    let salaryRegex = /^\d+$/;
    if (type === "title" || type === "all") {
      if (!expData?.title?.trim()) {
        formIsValid = false;
        setError({ ...error, title: "Title is mandatory." });
        return;
      } else if (!expData?.title.match(/^[a-zA-Z0-9_ ]*$/)) {
        formIsValid = false;
        setError({
          ...error,
          title: "Title doesn't contains special charactors",
        });
        return;
      } else {
        setError({ ...error, title: "" });
      }
    }

    if (type === "company_name" || type === "all") {
      if (!expData.company_name.trim()) {
        formIsValid = false;
        setError({ ...error, company_name: "Employer name is mandatory." });
        return;
      } else {
        setError({ ...error, company_name: "" });
      }
    }
    if (type === "employment_type" || type === "all") {
      if (!expData.employment_type) {
        formIsValid = false;
        setError({
          ...error,
          employment_type: "Employment type  is mandatory",
        });
        return;
      } else {
        setError({ ...error, employment_type: "" });
      }
    }

    if (type === "salary_type" || type === "all") {
      if (!expData.salary_type) {
        formIsValid = false;
        setError({ ...error, salary_type: "Salary type is mandatory" });
        return;
      } else {
        setError({ ...error, salary_type: "" });
      }
    }

    if (type === "gender" || type === "all") {
      if (!expData.gender) {
        formIsValid = false;
        setError({ ...error, gender: "Gender is mandatory" });
        return;
      } else {
        setError({ ...error, gender: "" });
      }
    }

    if (type === "age" || type === "all") {
      if (!expData.age) {
        formIsValid = false;
        setError({ ...error, age: "Age is mandatory" });
        return;
      } else {
        setError({ ...error, age: "" });
      }
    }

    if (type === "job_location" || type === "all") {
      if (!expData.job_location) {
        formIsValid = false;
        setError({ ...error, job_location: "Job location is mandatory" });
        return;
      } else {
        setError({ ...error, job_location: "" });
      }
    }

    if (type === "profession" || type === "all") {
      if (!expData.profession) {
        formIsValid = false;
        setError({ ...error, profession: "Profession is mandatory" });
        return;
      } else {
        setError({ ...error, profession: "" });
      }
    }

    if (type === "nationality" || type === "all") {
      if (!expData.nationality.length) {
        formIsValid = false;
        setError({ ...error, nationality: "Nationality is mandatory" });
        return;
      } else {
        setError({ ...error, nationality: "" });
      }
    }
    if (type === "description" || type === "all") {
      if (!expData.description) {
        formIsValid = false;
        setError({ ...error, description: "Description is mandatory" });
        return;
      } else if (expData?.description.length < 200) {
        formIsValid = false;
        setError({
          ...error,
          description: "Description should atleast 200 characters long",
        });
        return;
      } else {
        setError({ ...error, description: "" });
      }
    }
    if (type === "min_experience" || type === "all") {
      if (!expData.min_experience) {
        formIsValid = false;
        setError({ ...error, min_experience: "Min experience is mandatory" });
        return;
      } else {
        setError({ ...error, min_experience: "" });
      }
    }
    if (type === "max_experience" || type === "all") {
      if (!expData.max_experience) {
        formIsValid = false;
        setError({ ...error, max_experience: "Max experience is mandatory" });
        return;
      } else if (+expData?.min_experience >= +expData?.max_experience) {
        formIsValid = false;
        setError({
          ...error,
          max_experience: "Max experience should greater then min experience",
        });
        return;
      } else {
        setError({ ...error, max_experience: "" });
      }
    }
    console.log(
      typeof +expData?.min_salary,
      typeof +expData?.max_salary,
      expData?.min_salary >= expData?.max_salary
    );
    if (type === "min_salary" || type === "all") {
      if (!expData.min_salary) {
        formIsValid = false;
        setError({ ...error, min_salary: "Min salary is mandatory" });
        return;
      } else if (!salaryRegex.test(expData?.min_salary)) {
        formIsValid = false;
        setError({ ...error, min_salary: "Min salary should be in digit" });
        return;
      } else {
        setError({ ...error, min_salary: "" });
      }
    }
    if (type === "max_salary" || type === "all") {
      if (!expData.max_salary) {
        formIsValid = false;
        setError({ ...error, max_salary: "Max salary is mandatory" });
        return;
      } else if (!salaryRegex.test(expData?.max_salary)) {
        formIsValid = false;
        setError({ ...error, max_salary: "Min salary should be in digit" });
        return;
      } else if (+expData?.min_salary >= +expData?.max_salary) {
        formIsValid = false;
        setError({
          ...error,
          max_salary: "Max salary should greater then min salary",
        });
        return;
      } else {
        setError({ ...error, max_salary: "" });
      }
    }

    if (type == "account_type" || type === "all") {
      if (!expData.account_type) {
        console.log(expData);
        formIsValid = false;
        setError({ ...error, account_type: "Account type is mandatory" });
        return;
      } else {
        setError({ ...error, account_type: "" });
      }
    }
    return formIsValid;
  };

  const totalExp = (start, end) => {
    var today = new Date();
    var startDate = new Date(start);
    var endDate = new Date(end ? end : today);
    var startMonth = startDate.getFullYear() * 12 + startDate.getMonth();
    var endMonth = endDate.getFullYear() * 12 + endDate.getMonth();
    var monthInterval = endMonth - startMonth;

    var yearsOfExperience = Math.floor(monthInterval / 12);
    var monthsOfExperience = monthInterval % 12;
    return yearsOfExperience + "." + monthsOfExperience;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkValidation("all")) {
      setCon(1);
    }
  };
  const getCategory = () => {
    dispatch(getAllProfession());
  };

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setExpData({ ...expData, [e.target.name]: e.target.value });
  };

  const getEmploymentType = async () => {
    try {
      const res = await call("get", "api/v1/employment-type", null, null);
      setEmploymentTypeList(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllReligion());
    getEmploymentType();
  }, []);

  console.log(expData, currencyCodes);
  const [listImage, setListImage] = useState("");

  return (
    <>
      <HeaderLogo />
      <div className={"work-experience-page workerpool-default-form-page"}>
        {con == 0 && (
          <>
            <div className="container">
              <div className="width-850" style={{ margin: "auto" }}>
                <div className="head">
                  <h3>Add Job Details</h3>
                </div>
                <form className="form-box">
                  <div className="row">
                    <FormGroup className="col-sm-6">
                      <Label>Title</Label>
                      <Input
                        // ref={titleRef}
                        // onClick={(e) => setErrorTitle("")}
                        maxLength="50"
                        onBlur={() => checkValidation("title")}
                        value={expData?.title}
                        name="title"
                        onChange={(e) => handleChange(e)}
                        type="text"
                        placeholder="Enter title"
                      />
                      {error?.title && (
                        <label className="label-input100 text-danger">
                          {error?.title}
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup className="col-sm-6 school">
                      <Label>Employer</Label>
                      <Autocomplete
                        className="location"
                        inputProps={{
                          placeholder: "Enter employer name",
                        }}
                        maxLength={50}
                        value={expData?.company_name}
                        items={companyList}
                        getItemValue={(item) => item.name}
                        // shouldItemRender={(item, value) =>
                        //   item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                        // }
                        renderMenu={(items, value) => (
                          <div
                            className="dropdown bg-white p-3"
                            style={{
                              height: "150px",
                              overflow: "auto",
                              zIndex: 999,
                              border: "1px solid #d1dfff",
                            }}
                          >
                            {items.length === 0 ? (
                              `No matches for ${value}`
                            ) : items.length >= 50 ? (
                              <p style={{ cursor: "pointer" }}>
                                {items.slice(0, 50)}...
                              </p>
                            ) : (
                              <p style={{ cursor: "pointer" }}>{items}</p>
                            )}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                            style={{ display: "flex" }}
                          >
                            <img
                              style={{
                                height: "35px",
                                width: "35px",
                                borderRadius: "50%",
                              }}
                              src={item.icon ? item.icon : buld}
                              alt=""
                            />
                            <div className="mx-2">
                              <div className="name-heading-drop ">
                                {item.name}
                              </div>
                            </div>
                          </div>
                        )}
                        onChange={(obj, val) => {
                          console.log(val, "val");
                          getCompanyList(val);
                          setExpData({ ...expData, company_name: val });
                        }}
                        onSelect={(val, obj) => {
                          setListImage(obj.icon || buld);
                          setExpData({
                            ...expData,
                            company_name: val,
                            company_id: companyList.find(
                              (ele) => ele.name == val
                            )?.id,
                          });
                        }}
                        wrapperStyle={{}}
                        renderInput={(params) => {
                          return (
                            <div
                              class="fake-input"
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                alignItems: "center",
                                border: "1px solid var(--borderline, #d1dfff)",
                                borderRadius: "5px",
                              }}
                            >
                              <input
                                {...params}
                                type="text"
                                placeholder="Company name"
                                style={{ border: "none" }}
                              />

                              <img
                                style={{
                                  height: "35px",
                                  width: "35px",
                                  borderRadius: "50%",
                                }}
                                src={listImage || buld}
                                alt=""
                              />
                            </div>
                          );
                        }}
                      />
                      {error?.company_name && (
                        <label className="label-input100 text-danger">
                          {error?.company_name}
                        </label>
                      )}
                    </FormGroup>

                    {console.log(companyList, "companyLisy")}

                    <FormGroup className="col-sm-6"></FormGroup>

                    <FormGroup className="col-sm-12">
                      <label htmlFor="">Description</label>
                      <textarea
                        value={expData?.description}
                        maxLength="500"
                        onBlur={() => checkValidation("description")}
                        placeholder="Enter description"
                        name="description"
                        onChange={(e) => {
                          handleChange(e);
                          setTextLength(
                            e.target.value.trim() === ""
                              ? 0
                              : e.target.value.length
                          );
                        }}
                        rows={5}
                      />
                      {error?.description && (
                        <label className="label-input100 text-danger">
                          {error?.description}
                        </label>
                      )}
                      <p className="left" style={{ float: "inline-end" }}>
                        {textLength}/500
                      </p>
                    </FormGroup>
                    <FormGroup className="col-sm-6">
                      <Label>Category</Label>
                      <Input
                        name="select"
                        type="select"
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="">Select</option>
                        {catergoryList?.map((item, index) => (
                          <option value={item?.category} key={index}>
                            {item?.category}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup className="col-sm-6">
                      <Label>Profession</Label>
                      <div className=" icon">
                        <select
                          name="profession"
                          onBlur={() => checkValidation("profession")}
                          className="form-select"
                          options={professionList}
                          onChange={(e) => handleChange(e)}
                          disabled={category ? false : true}
                          // value={basicForm.profession}
                        >
                          <option value="">Select</option>
                          {professionList?.map((item, index) => (
                            <option value={item?.name} key={index}>
                              <input type="checkbox" id={index} />
                              <label for="vehicle1"> {item?.name}</label>
                              <br></br>
                            </option>
                          ))}
                        </select>
                        {error?.profession && (
                          <label className="label-input100 text-danger">
                            {error?.profession}
                          </label>
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup className="col-sm-6">
                      <Label>Min-Experience</Label>
                      <Input
                        onBlur={() => checkValidation("min_experience")}
                        name="min_experience"
                        onChange={(e) => handleChange(e)}
                        type="select"
                      >
                        <option value="0">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </Input>
                      {error?.min_experience && (
                        <label className="label-input100 text-danger">
                          {error?.min_experience}
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup className="col-sm-6">
                      <Label>Max-Experience</Label>
                      <Input
                        type="select"
                        onBlur={() => checkValidation("max_experience")}
                        name="max_experience"
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="0">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </Input>
                      {error?.max_experience && (
                        <label className="label-input100 text-danger">
                          {error?.max_experience}
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup className="col-sm-6">
                      <Label>Job Region</Label>
                      {/* <Input
                        onBlur={() => checkValidation("job_location")}
                        name="job_location"
                        onChange={(e) => handleChange(e)}
                        type="select"
                      >
                        <option value="0">Select</option>
                        {countryList?.map((item, index) => (
                          <option value={item?.name} key={index}>
                            {item?.name}
                          </option>
                        ))}
                      </Input> */}

                      <Autocomplete
                        className="location"
                        inputProps={{
                          placeholder: "Location",
                          onBlur: () => checkValidation("job_location"),
                        }}
                        value={expData?.job_location}
                        items={locationList}
                        getItemValue={(item) => item.name}
                        renderMenu={(items, value) => (
                          <div
                            className="dropdown bg-white p-3"
                            style={{
                              height: "150px",
                              overflow: "auto",
                              zIndex: 999,
                              border: "1px solid #d1dfff",
                            }}
                          >
                            {items.length === 0
                              ? `No matches for ${value}`
                              : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                          >
                            {item.name}
                          </div>
                        )}
                        onChange={(obj, val) => {
                          getLocation(val);
                          setExpData({ ...expData, job_location: val });
                        }}
                        onSelect={(val, obj) => {
                          console.log("Selected: ", val, obj);
                          setExpData({ ...expData, job_location: val });
                        }}
                        wrapperStyle={{}}
                      />
                      {error?.job_location && (
                        <label className="label-input100 text-danger">
                          {error?.job_location}
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup className="col-sm-6">
                      <Label>Religion (optional)</Label>
                      <Input
                        name="religion"
                        onChange={(e) => handleChange(e)}
                        type="select"
                      >
                        <option value="0">Select</option>
                        {religionList?.map((ele) => {
                          return (
                            <>
                              <option value={ele?.religion}>
                                {ele?.religion}
                              </option>
                            </>
                          );
                        })}
                      </Input>
                      {/* {error?.rel && (
                  <label className="label-input100 text-danger">
                    {error?.rel}
                  </label>
                )} */}
                    </FormGroup>
                    {/* <div className="row w-100"> */}
                    <FormGroup className="col-sm-4">
                      <Label>Salary Type</Label>
                      <div className="d-flex">
                        <Input
                          onClick={() => checkValidation("salary_type")}
                          name="salary_type"
                          onChange={(e) => handleChange(e)}
                          type="select"
                          className=""
                        >
                          <option value="">Select</option>
                          {Object.keys(currencyCodes)?.map((ele) => {
                            return (
                              <>
                                <option value={ele} key={ele}>
                                  {ele}
                                </option>
                              </>
                            );
                          })}
                        </Input>
                      </div>
                      {error?.salary_type && (
                        <label className="label-input100 text-danger">
                          {error?.salary_type}
                        </label>
                      )}
                    </FormGroup>

                    <FormGroup className="col-sm-4">
                      <Label>Min Salary</Label>
                      <div className="d-flex">
                        <Input
                          onBlur={() => checkValidation("min_salary")}
                          name="min_salary"
                          onChange={(e) => handleChange(e)}
                          type="text"
                          maxLength={7}
                        ></Input>
                      </div>
                      {error?.min_salary && (
                        <label className="label-input100 text-danger">
                          {error?.min_salary}
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup className="col-sm-4">
                      <Label>Max Salary</Label>
                      <div className="d-flex">
                        <Input
                          onBlur={() => checkValidation("max_salary")}
                          name="max_salary"
                          onChange={(e) => handleChange(e)}
                          type="text"
                          maxLength={7}
                        ></Input>
                      </div>
                      {error?.max_salary && (
                        <label className="label-input100 text-danger">
                          {error?.max_salary}
                        </label>
                      )}
                    </FormGroup>
                    <div className="col-sm-6">
                      <div className="row">
                        <FormGroup className="col-sm-6">
                          <Label>Gender</Label>
                          <Input
                            name="gender"
                            onChange={(e) => handleChange(e)}
                            onBlur={() => checkValidation("gender")}
                            type="select"
                            className="small-input"
                          >
                            <option value="0">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </Input>
                          {error?.gender && (
                            <label className="label-input100 text-danger">
                              {error?.gender}
                            </label>
                          )}
                        </FormGroup>
                        <FormGroup className="col-sm-6">
                          <Label>Age</Label>
                          <Input
                            onBlur={() => checkValidation("age")}
                            name="age"
                            onChange={(e) => handleChange(e)}
                            type="select"
                            className="small-input"
                          >
                            <option value="0">Select</option>
                            {Array.from({ length: 33 }).map((_, index) => (
                              <option key={index} value={index + 18}>
                                {index + 18}
                              </option>
                            ))}
                          </Input>
                          {error?.age && (
                            <label className="label-input100 text-danger">
                              {error?.age}
                            </label>
                          )}
                        </FormGroup>
                      </div>
                    </div>
                    <FormGroup className="col-sm-6">
                      <Label>Employment Type</Label>
                      <Input
                        name="employment_type"
                        onBlur={() => checkValidation("employment_type")}
                        onChange={(e) => handleChange(e)}
                        type="select"
                        className="small-input"
                      >
                        <option value="0">Select</option>
                        {employmentTypeList?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                      {error?.employment_type && (
                        <label className="label-input100 text-danger">
                          {error?.employment_type}
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup className="col-sm-6">
                      <Label>Account Type</Label>
                      <Input
                        name="account_type"
                        type="select"
                        onBlur={() => checkValidation("account_type")}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="starter">Starter</option>
                        <option value="nobel">Nobel</option>
                        <option value="nobelplus">Nobel+</option>
                        <option value="scout">Scout</option>
                      </Input>
                      {error?.account_type && (
                        <label className="label-input100 text-danger">
                          {error?.account_type}
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup className="col-sm-6">
                      <Label>Nationality</Label>
                      <Input
                        name="nationality"
                        onBlur={() => checkValidation("nationality")}
                        onChange={(e) => {
                          setExpData({
                            ...expData,
                            nationality: [
                              ...expData?.nationality,
                              e.target.value,
                            ],
                          });
                        }}
                        type="select"
                        className="small-input"
                      >
                        <option value="0">Select</option>
                        {countryList?.map((ele) => {
                          return (
                            <>
                              <option value={ele?.name}>{ele?.name}</option>
                            </>
                          );
                        })}
                      </Input>
                      {error?.nationality && (
                        <label className="label-input100 text-danger">
                          {error?.nationality}
                        </label>
                      )}
                    </FormGroup>

                    <div className="mt-2">
                      <Label className="d-flex align-items-center">
                        <Input
                          type="checkbox"
                          checked={expData?.contact_detail}
                          id="check"
                          onChange={(e) =>
                            setExpData({
                              ...expData,
                              contact_detail: !expData?.contact_detail,
                            })
                          }
                        />
                        {expData?.contact_detail
                          ? "Show contact details"
                          : "Hide contact details (only apply option)"}
                      </Label>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn-blue p-2 w-auto"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}

        {con == 1 && (
          <>
            <JobPreview
              setCon={setCon}
              data={expData}
              setExpData={setExpData}
              user={user}
              currencyCodes={currencyCodes}
            />
          </>
        )}
      </div>
    </>
  );
};

export default AddJobDetails;
