import { call } from "./apiCall";

class ProfileApi {
  getWorkerProfile(id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/public/worker/${id}`,
            null,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  getNobelProfile(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/profile/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  postUserDetails(userData, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/user/profile`,
            null,
            userData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  postWorkerUserDetails(userData, token, id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/worker/update/${id}`,
            null,
            userData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  getUserDetailsByID(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/profile/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  getWPuserbyId(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/worker/${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(null);
        }
      })();
    });
  }

  publicYouProfile(token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/worker/public-profile`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          resolve(err);
        }
      })();
    });
  }

  addPage(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", `api/v1/page`, null, data, token);
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  deleteProfileSubData(type, id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/${type}/${id}`,
            null,
            {},
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  getSearchHistory(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/search-history`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }

  createSearchHistory(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/search-history`,
            {},
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }

  deactivateAccount() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/worker/hybernate-account`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
            localStorage.removeItem("accessToken");
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          resolve(err);
        }
      })();
    });
  }

  deleteAccount() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/worker/close-account`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
            localStorage.removeItem("accessToken");
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          resolve(err);
        }
      })();
    });
  }
}

export const profileApi = new ProfileApi();
