import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import { publishAsPublic } from "../../../store/profile/ProfileAction";
import { toast } from "react-toastify";

const PublishPaymentModal = (props) => {
  const { modal, toggle, setPublishModal } = props;
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");

  const handlePublish = () => {
    dispatch(publishAsPublic(token)).then((response) => {
      toggle();
      toast.success("Your profile is published as public for 30 days", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  useEffect(() => {
    setPublishModal(false);
  }, []);

  return (
    <Modal
      isOpen={modal}
      size="md"
      toggle={toggle}
      style={{ maxWidth: "876px" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-worker-payment workerpool-default-form-page">
        <ModalBody>
          {/* {errorMessage && (
            <label className="label-input100 text-danger">{errorMessage}</label>
          )} */}
          <h3>Payment</h3>
          <p className="mt-2">
            Listing in private mode is free, visible to NobelPage
            scouts/recruiters. Activate Public Mode for 30 days on WorkerPool
            for wider visibility to companies and visitors.
          </p>
          <div className="d-flex justify-content-between mt-4">
            <h5 className="font-bold mt-0">
              Publish Your Profile for a 1 time Payment
            </h5>
            <span className="payment-num">$10.00</span>
          </div>
          <form className="form-box">
            <FormGroup>
              <Label>Card Number</Label>
              <Input type="text" />
            </FormGroup>
            <FormGroup>
              <Label>Name of Card</Label>
              <Input type="text" />
            </FormGroup>
            <div className="row">
              <FormGroup className="col-sm-6">
                <Label>Validity</Label>
                <Input type="text" />
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label>CVV</Label>
                <Input type="text" />
              </FormGroup>
              <FormGroup>
                <Label className="mx-2">
                  <Input type="checkbox" />
                  Save this card
                </Label>
              </FormGroup>
              <div className="text-center mt-5">
                <Button className="btn-blue" onClick={() => handlePublish()}>
                  Publish and Pay Now
                </Button>
              </div>
            </div>
          </form>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default PublishPaymentModal;
