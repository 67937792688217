import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchHistory } from "../../store/profile/ProfileAction";
import HeaderLogo from "../Header/header";
import clockIcon from "../../assets/img/clockIcon.png";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/img/backArrow.svg";
import AppLoader from "../../core/Loaders/appLoader/appLoader";
import "./SearchHistory.scss";
const SearchHistory = () => {
  const { searchHistory } = useSelector((state) => state.profile);
  const [limit, setLimit] = useState(100);
  const [skip, setSkip] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isPageLoading } = useSelector((state) => state.loader);

  // useEffect(()=>{
  //   dispatch(getSearchHistory({platform_type : "wp", limit, skip}))
  // }, [])

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const currentHeight =
        document.documentElement.scrollTop + window.innerHeight;
      if (currentHeight + 1 >= scrollHeight) {
        console.log();
        setSkip(skip + 10);
      }
    };

    window.addEventListener("scroll", handleScroll);

    dispatch(getSearchHistory({ platform_type: "wp", limit, skip }));

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [skip]);

  return (
    <>
      <HeaderLogo />
      <div className="container">
        <div className="width-850">
          {isPageLoading && <AppLoader />}

          <div className="workerpool-default-form-page">
            <div className="back-btn" onClick={() => navigate(-1)}>
              <img src={backArrow} alt="Icon" />
            </div>
          </div>
          <h2>Search History</h2>

          <div className="mt-5">
            {searchHistory?.length == 0 && (
              <p className="text-center">No History Found</p>
            )}
            {searchHistory?.map((ele) => {
              return (
                <>
                  <div className="d-flex mt-3">
                    <img src={clockIcon} height={24} width={24} />
                    <span className="title">{ele?.search}</span>
                    <span className="type">
                      {ele?.type?.charAt(0)?.toUpperCase() +
                        ele?.type?.slice(1)}{" "}
                      search
                    </span>
                  </div>
                  <hr />
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchHistory;
