import React from "react";
import "./appLoader.scss";
import logo from "../../../assets/img/wpLoader.gif";

export default function AppLoader() {
  return (
    <div className="appLoader">
      <img
        src={logo}
        style={{ height: "45px", width: "45px", borderRadius: "50%" }}
        alt="logo"
      />
      <div className="mt-10">Loading...</div>
    </div>
  );
}
