import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Componenets/Sidebar";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "../WorkerPoolProfile.scss";
import "../../WorkerPoolFormPages/WorkerPoolDefaultForm.scss";
import { Link } from "react-router-dom";
import backArrow from "../../../assets/img/backArrow.svg";
import edit from "../../../assets/img/edit.png";
import googleDocs from "../../../assets/img/google-docs.png";
import rightArrow from "../../../assets/img/rightArrowIcon.png";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  updateNobelUserDetails,
  uploadResume,
} from "../../../store/profile/ProfileAction";
import { toast } from "react-toastify";
import uploadIcon from "../../../assets/img/upload-icon.svg";
import moment from "moment";
import dustBin from "../../../assets/img/deleteBlack.png";
import { upDateUserData } from "../../../store/auth/authActions";
import { initLoad, stopLoad } from "../../../store/loader/loaderActions";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";
const ProfileResume = (props) => {
  const { setProfileField, nobelprofileData } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const inputRef = useRef();
  const addInputRef = useRef();

  const onChangeResume = async (e) => {
    let token = localStorage.getItem("accessToken");
    setLoading(true);
    if (e.target.files[0]) {
      let tempimage = [];
      let type = "file";
      Array.from(e.target.files).map((file) => {
        return tempimage.push({ type, file });
      });
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        for (var i = 0; i < tempimage.length; i++) {
          formData.append("image", tempimage[i].file);
        }

        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          if (res?.status == 200) {
            let data = {
              resume_url: res?.data?.urlsArray[0],
              resume_name: res?.data?.filesArray[0].originalname,
              resume_date: new Date(),
            };
            dispatch(uploadResume(data, token)).then((response) => {
              if (response.success) {
                toast.success("You have successfully uploaded resume", {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
              }
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    } else {
      toast.error("Please select file", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const handleSubmit = () => {};

  const handleDelete = () => {
    dispatch(initLoad());
    let token = localStorage.getItem("accessToken");
    let data = {
      resume_url: "",
      resume_name: "",
      resume_date: null,
    };
    dispatch(updateNobelUserDetails(data, token))
      .then((res) => dispatch(stopLoad()))
      .catch((err) => dispatch(stopLoad()));
  };

  return (
    <div className="profile-content-outer">
      {loading && <AppLoader />}
      <div className="workerpool-default-form-page" style={{ width: "80%" }}>
        <div className="back-btn" onClick={() => setProfileField("profile")}>
          <img src={backArrow} alt="Icon" />
        </div>
        <div className="head progress-box">
          <h3>Resume</h3>
        </div>
        {/* {nobelprofileData?.user?.profile?.resume_url && !resume ? (
          <>
            <div className="d-flex justify-content-between resume-edit-box align-items-center">
              <div className="d-flex align-items-center gap-2">
                <div className="icon">
                  <img src={googleDocs} alt="Icon" />
                </div>
                <div className="heading">
                
                  <h4
                    onClick={() =>
                      window.open(nobelprofileData?.user?.profile?.resume_url)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {nobelprofileData?.user?.profile?.resume_name.slice(0, 63) +
                      (nobelprofileData?.user?.profile?.resume_name.length > 63
                        ? "..."
                        : "")}
                  </h4>
                  {nobelprofileData?.user?.profile?.resume_date && (
                    <span className="time">
                      updated{" "}
                      {moment(nobelprofileData?.user?.profile?.resume_date)
                        ?.utc()
                        ?.format("MMM DD YYYY")}
                    </span>
                  )}
                </div>
              </div>
              <span className="edit-btn">
                <input
                  ref={inputRef}
                  type="file"
                  id="upload"
                  name="upload"
                  accept="application/pdf,application/vnd.ms-excel"
                  style={{ display: "none" }}
                  onChange={onChangeResume}
                  onClick={(e) => (e.target.value = "")}
                />
                <img
                  src={edit}
                  alt="Icon"
                  onClick={() => {
                    setIsEditResume(true);
                    inputRef?.current?.click();
                  }}
                />
              </span>
            </div>
          </>
        ) : ( */}
        <div
          className="add-resume"
          style={{
            padding: "25px",
            width: "100%",
            border: "1px dashed #B9B9B9",
            display: "flex",
            justifyContent: "center",
            borderRadius: "10px",
            flexDirection: "column",
            alignItems: "center",
            background: "#f5f5f5",
          }}
        >
          <input
            ref={addInputRef}
            type="file"
            id="upload"
            name="upload"
            accept="application/pdf,application/vnd.ms-excel"
            style={{ display: "none" }}
            onChange={(e) => onChangeResume(e)}
            onClick={(e) => (e.target.value = "")}
          />
          <img src={uploadIcon} width={30} />
          <h3
            className="mt-2"
            onClick={() => {
              addInputRef?.current?.click();
            }}
            style={{
              textDecoration: "underline",
              fontSize: "20px",
              fontWeight: 400,
            }}
          >
            Upload Resume
          </h3>
        </div>
        {/* {resume && (
          <div className="add-media">
            <div className="d-flex justify-content-between resume-edit-box align-items-center">
              <div className="d-flex align-items-center gap-2">
                <div className="icon">
                  <img src={googleDocs} alt="Icon" />
                </div>
                <div className="heading">
                  <h4>
                    {" "}
                    {fileName.slice(0, 65) +
                      (fileName.length > 65 ? "..." : "")}
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="btn btn-blue mb-20"
              style={{ marginTop: "15px" }}
              onClick={handleSubmit}
            >
              Save
            </div>
          </div>
        )} */}

        <div className="gradient-box mt-3">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-sm-7">
              <h3>Make Resume with NobelPage</h3>
              <p>
                Explore additional features with NobelPage, like crafting a free
                ATS-friendly CV and more. Join us and become one of our valued
                members today!
              </p>
            </div>
            <div
              to="#"
              className="white-btn align-self-center"
              onClick={() =>
                window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/cv`)
              }
            >
              Check
              <img src={rightArrow} alt="right-arrow" />
            </div>
          </div>
        </div>

        {nobelprofileData?.user?.profile?.resume_url && (
          <>
            <div className="d-flex justify-content-between resume-edit-box align-items-center">
              <div className="d-flex align-items-center gap-2">
                <div className="icon">
                  <img src={googleDocs} alt="Icon" />
                </div>
                <div className="heading">
                  <h4
                    onClick={() =>
                      window.open(nobelprofileData?.user?.profile?.resume_url)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {nobelprofileData?.user?.profile?.resume_name.slice(0, 63) +
                      (nobelprofileData?.user?.profile?.resume_name.length > 63
                        ? "..."
                        : "")}
                  </h4>
                  {/* </a> */}
                  {nobelprofileData?.user?.profile?.resume_date && (
                    <span className="time">
                      updated{" "}
                      {moment(nobelprofileData?.user?.profile?.resume_date)
                        ?.utc()
                        ?.format("MMM DD YYYY")}
                    </span>
                  )}
                </div>
              </div>
              <span className="edit-btn">
                <input
                  ref={inputRef}
                  type="file"
                  id="upload"
                  name="upload"
                  accept="application/pdf,application/vnd.ms-excel"
                  style={{ display: "none" }}
                  onChange={onChangeResume}
                  onClick={(e) => (e.target.value = "")}
                />
                <img
                  src={dustBin}
                  alt="Icon"
                  onClick={() => {
                    handleDelete();
                  }}
                />
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileResume;
