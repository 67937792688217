import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, Progress } from "reactstrap";
import "../WorkerPoolDefaultForm.scss";
import edit from "../../../assets/img/edit.png";
import backArrow from "../../../assets/img/backArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addlanguagedetails,
  getNobelUserProfile,
  getWPUserProfileById,
  removeProfileSubData,
} from "../../../store/profile/ProfileAction";
import { call } from "../../../api/apiCall";
import Autocomplete from "react-autocomplete";
import { useNavigate } from "react-router-dom";
import HeaderLogo from "../../Header/header";
import { toast } from "react-toastify";
import Loader from "../../../core/Loaders/ComponentLoader";
import dustbin from "../../../assets/img/dustbin.svg";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";
const Languages = (props) => {
  let { setCountinue, user, isProfilePage, setProfileField, token } = props;
  const [editLanguageData, setEditLanguageData] = useState();
  const { nobelprofileData } = useSelector((state) => state.profile);
  const [errorMessage, setErrorMessage] = useState("");
  const [proficiencyList, setProficiencyList] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [languageInput, setLanguageInput] = useState(
    editLanguageData?.name ? editLanguageData?.name : ""
  );
  const navigate = useNavigate();
  const [expData, setExpData] = useState({
    name: "",
    proficiency: "",
  });
  const [error, setError] = useState({
    name: "",
    proficiency: "",
  });
  const dispatch = useDispatch();
  token = token || localStorage.getItem("accessToken");
  const { isLoading } = useSelector((state) => state.loader);
  // useEffect(() => {
  //  console.log("expData",expData);
  // }, [expData])

  useEffect(() => {
    let id = user?.worker?.id;
    if (id) {
      dispatch(getWPUserProfileById(id, token)).then((response) => {
        if (response.success === false) return;
      });
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          "api/v1/language-proficiency",
          null,
          null
        );
        setProficiencyList(res?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/locale", null, null);
        setLanguageList(res.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const checkValidation = (type) => {
    let isValid = true;
    if (type == "name" || type == "all") {
      if (!expData?.name?.trim()) {
        setError({ ...error, name: "Mandatory Field" });
        isValid = false;
        return;
      } else {
        setError({ ...error, name: "" });
      }
    }

    if (type == "proficiency" || type == "all") {
      console.log(expData?.proficiency);
      if (!expData?.proficiency) {
        setError({ ...error, proficiency: "Mandatory Field" });
        isValid = false;
        return;
      } else {
        setError({ ...error, proficiency: "" });
      }
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newData = expData;
    Object.keys(newData).forEach((key) => {
      if (!newData[key]) delete newData[key];
    });
    if (checkValidation("all")) {
      let expDataformat = {
        languages: [newData],
      };
      var token = localStorage.getItem("accessToken");
      dispatch(addlanguagedetails(expDataformat, token))
        .then((res) => {
          if (res?.success) {
            {
              isEdit
                ? toast.success("Language updated Successfully", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  })
                : toast.success("Language added Successfully", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
            }
            if (editLanguageData) {
              setEditLanguageData({});
              setIsEdit(-1);
            }
            setExpData({
              name: "",
              proficiency: "",
            });
            setLanguageInput("");
          }
        })

        .catch((e) => {
          console.log(e);
          alert("Something went wrong.");
        });
    }
  };

  useEffect(() => {
    setExpData({
      id: editLanguageData?.id,
      name: editLanguageData?.name,
      proficiency: editLanguageData?.proficiency,
    });
    setLanguageInput(editLanguageData?.name);
  }, [editLanguageData]);

  const handleSave = (e) => {
    e.preventDefault();
    navigate("/", { state: { signup: true } });
  };

  const handleDelete = (id) => {
    dispatch(removeProfileSubData("language", id));
  };

  return (
    <>
      {isLoading && <AppLoader />}
      {!isProfilePage && <HeaderLogo />}
      <div
        className={
          "education-info-page workerpool-default-form-page" +
          (isProfilePage ? " my-4" : "")
        }
      >
        <div className="container">
          <div className="width-850">
            {isProfilePage && (
              <div className="workerpool-default-form-page">
                <div
                  className="back-btn"
                  onClick={() => setProfileField("profile")}
                >
                  <img src={backArrow} alt="Icon" />
                </div>
              </div>
            )}
            <div className="head progress-box">
              <h3 className="mb-2">Languages</h3>
              {!isProfilePage && <Progress value={100} />}
            </div>
            <form className="form-box">
              <div className="row">
                <FormGroup className="col-sm-5">
                  <Label>Select Language</Label>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                      inputProps={{
                        placeholder: "Enter Language",
                        onBlur: () => checkValidation("name"),
                      }}
                      value={languageInput}
                      items={languageList}
                      getItemValue={(item) => item.name}
                      shouldItemRender={(item, value) =>
                        item.name.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      renderMenu={(items, value) => (
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "10px" }}
                        >
                          {items}
                          {/* {items.length === 0 ? `No matches for ${value}` : items} */}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          // className={`item ${
                          //   isHighlighted ? "selected-item" : ""
                          // }`}
                          style={{
                            background: isHighlighted ? "#19bcf4" : "white",
                          }}
                        >
                          {item.name}
                        </div>
                      )}
                      onChange={(obj, val) => {
                        setLanguageInput(val);
                        setExpData({ ...expData, name: obj.name });
                        setErrorMessage("");
                      }}
                      onSelect={(val, obj) => {
                        setLanguageInput(val);
                        setExpData({ ...expData, name: obj.name });
                      }}
                      wrapperStyle={{}}
                    />
                    {error?.name && (
                      <label className="label-input100 text-danger">
                        {error?.name}
                      </label>
                    )}
                  </div>
                </FormGroup>
                <FormGroup className="col-sm-5">
                  <Label>Proficiency</Label>
                  <select
                    className="form-select"
                    value={expData?.proficiency}
                    id="associatedwith"
                    style={{ color: "#676A79" }}
                    name="proficiency"
                    onBlur={() => checkValidation("proficiency")}
                    onChange={(e) =>
                      setExpData({
                        ...expData,
                        proficiency: e.target.value,
                      })
                    }
                  >
                    <option value="">Select</option>
                    {proficiencyList?.data?.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {error?.proficiency && (
                    <label className="label-input100 text-danger">
                      {error?.proficiency}
                    </label>
                  )}
                </FormGroup>
                <FormGroup
                  className={
                    error?.name || error?.proficiency
                      ? "col-sm-2 col-lg-2 col-2 text-center text-lg-end align-self-center mt-2"
                      : "col-sm-2 col-lg-2 col-2 text-center text-lg-end align-self-end mb-3"
                  }
                >
                  <div className="">
                    <Button
                      className="btn-blue w-100"
                      onClick={(e) => handleSubmit(e)}
                    >
                      {isEdit > 0 ? "Save" : "Add"}
                    </Button>
                  </div>
                </FormGroup>
                <div className="col-sm-12 mt-5">
                  {nobelprofileData?.user?.languages?.length > 0 && (
                    <div className="line mb-1"></div>
                  )}
                  {nobelprofileData?.user?.languages?.map((item, index) => (
                    <div className="edit-box" key={index}>
                      <div className="d-flex justify-content-between">
                        <h4>{item?.name}</h4>
                        <div className="">
                          <span
                            className="edit-btn"
                            onClick={() => {
                              setEditLanguageData(item);
                              setIsEdit(item.id);
                            }}
                          >
                            {isEdit != item.id && <img src={edit} alt="Icon" />}
                          </span>

                          <span className="edit-btn mx-3">
                            <img
                              src={dustbin}
                              onClick={() => handleDelete(item.id)}
                            />
                          </span>
                        </div>
                      </div>
                      <h5>{item?.proficiency}</h5>
                    </div>
                  ))}
                </div>
              </div>
              {!isProfilePage && (
                <div className="text-end mt-5 d-flex justify-content-between">
                  <Button
                    className="btn-outline no-bg mx-0 mx-md-4 mb-5"
                    onClick={() => setCountinue(3)}
                  >
                    Back
                  </Button>
                  <div className="d-flex skip">
                    <h5
                      className="skip_btn mb-5 mx-4 me-3"
                      onClick={(e) => handleSave(e)}
                    >
                      Skip
                    </h5>
                    <Button
                      className="btn-blue mb-5 mx-3 me-0"
                      onClick={(e) => handleSave(e)}
                    >
                      Save & Continue
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Languages;
