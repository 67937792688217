import React, { useState } from "react";
import HeaderLogo from "../Header/header";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./createProvider.scss";
import smallWhiteLogo from "../../assets/img/npIcon.svg";
import { NavLink } from "react-router-dom";
import AskNobePage from "../login/component/AskNobePage";
const CreateProvider = () => {
  const [askNobelModal, setAskNobelModal] = useState(false);

  return (
    <>
      <HeaderLogo />

      <Modal isOpen={true} size="lg" centered className="profilePermission">
        {/* <ModalHeader >
          
        </ModalHeader> */}

        <ModalBody className="p-3">
          <div className="modal-header d-flex flex-column  p-5 text-white">
            <h3 className="text-center">
              Login with NobelPage’s NobelPlus or Scout account
            </h3>
            <p className="text-center mt-3">
              As an 'I Provide Workers' user, you have to sign in with a
              NobelPage Membership as NobelPlus or Scout account to access this
              feature.
            </p>
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="noble-login-btns mb-3"
                onClick={() => setAskNobelModal(true)}
                style={{ border: "1px solid #29409C" }}
              >
                <img src={smallWhiteLogo} alt="logo" />
                Connect NobelPage
              </div>
            </div>

            <NavLink to="/worker/account-type" className="backBtn">
              Back
            </NavLink>
          </div>
        </ModalBody>

        {/* <ModalBody>

        </ModalBody> */}
      </Modal>

      {askNobelModal && (
        <AskNobePage
          modal={askNobelModal}
          toggle={() => setAskNobelModal(!askNobelModal)}
          type="provider"
          isLogin="false"
        />
      )}
    </>
  );
};

export default CreateProvider;
