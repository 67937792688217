import React, { useState, useEffect } from "react";
import "./error.scss";
import logo from "../../assets/img/logo.svg";

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <div className="error">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <h3 className="mt-5">
                  No Internet Connection. Please try again later.
                </h3>
                {/* <div className="logo mt-4">
                           <img src={logo} width="55px" alt=""/>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default NoInternetConnection;
