import {
  ADD_EDUCATION_DATA,
  ADD_EXPERIENCE_DATA,
  ADD_LANGUAGE_DATA,
  ADD_LICENSE_DATA,
  DELETE_INTRO_VIDEO,
  GET_DATA_BY_ID,
  GET_USER_DATA,
  GET_WORKER_PROFILE,
  GET_WP_DATA_BY_ID,
  PUBLISH_AS_PUBLIC,
  UPDATE_EDUCATION_DATA,
  UPDATE_EXPERIENCE_DATA,
  UPDATE_LANGUAGE_DATA,
  UPDATE_LICENSE_DATA,
  UPDATE_NOBEL_DATA,
  UPDATE_WORKER_INFO_DATA,
  UPLOAD_INTRO_VIDEO,
  UPLOAD_RESUME_DATA,
  ADD_BUSINESS_PAGE,
  REMOVE_EXPERIENCE,
  REMOVE_EDUCATION,
  REMOVE_LICENSE,
  REMOVE_LANGUAGE,
  GET_SEARCH_HISTORY
} from "../types";

const initialState = {
  workerProfileData: [],
  searchHistory : []
};
const profile = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_WORKER_PROFILE:
      return {
        ...state,
        workerProfileData: payload,
      };
    case GET_USER_DATA:
      // const { profileData } = action.payload
      return {
        ...state,
        nobelprofileData: payload,
      };
    case ADD_EDUCATION_DATA:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          user:{
            ...state.nobelprofileData.user,
             educations: [payload[0],...state.nobelprofileData.user.educations],
          }
         
        },
      };
    case GET_DATA_BY_ID:
      // const { profileData } = action.payload
      return {
        ...state,
        nobelprofileData: payload,
      };

    case GET_WP_DATA_BY_ID:
      return {
        ...state,
        nobelprofileData: payload,
      };
    case UPDATE_EDUCATION_DATA:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
         user:{
          ...state.nobelprofileData.user,
          educations: state.nobelprofileData.user.educations.map((x) =>
            x.id == payload[0].id ? {...x,...payload[0] } : x
          ),

         } 
        },
      };
    case ADD_EXPERIENCE_DATA:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          user:{
            ...state.nobelprofileData.user,
             experiences: [payload,...state.nobelprofileData.user.experiences],
          }
          
        },
      };

    case UPDATE_EXPERIENCE_DATA:
      let newExp = payload;

      let experiences = [...state.nobelprofileData.user.experiences];

      let updatedExpData = experiences.filter((item) => item.id !== payload.id);

      updatedExpData.unshift(newExp);
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
         user: {
            ...state.nobelprofileData.user,
            experiences: updatedExpData,
          }
          
        },
      };
    case ADD_LICENSE_DATA:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
         user:{
          ...state.nobelprofileData.user,
          license_certifications: [
            ...payload,
            ...state.nobelprofileData.user.license_certifications,
           
          ],

         } 
        },
      };

    case UPDATE_LICENSE_DATA:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          user:{
            ...state.nobelprofileData.user,
          license_certifications:
            state.nobelprofileData.user.license_certifications.map((x) =>
              x.id === payload[0].id ? { ...x,...payload[0] } : x
            ),
          }   
        },
      };
    case ADD_LANGUAGE_DATA:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          user:{
            ...state.nobelprofileData.user,
          languages: [...payload,...state.nobelprofileData.user.languages],
          }
        },
      };

    case UPDATE_LANGUAGE_DATA:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          user:{
            ...state.nobelprofileData.user,
          languages: state.nobelprofileData.user.languages.map((x) =>
            x.id === payload[0].id ? { ...x,...payload[0] } : x
          ),
          }
        },
      };
    case UPLOAD_INTRO_VIDEO:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          user:{
            ...state.nobelprofileData.user,
            profile:{
              ...state.nobelprofileData.user.profile,
          intro_video: payload.intro_video,
            }
          }
        },
      };

    case DELETE_INTRO_VIDEO:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          user:{
            ...state.nobelprofileData.user,
            profile:{
              ...state.nobelprofileData.user.profile,
          intro_video: payload.intro_video,
            }
          }
        },
      };
    case UPDATE_NOBEL_DATA:
      return {
        ...state,
        nobelprofileData: { ...state.nobelprofileData, ...payload },
      };
    case UPDATE_WORKER_INFO_DATA:
      console.log("payload", payload);
      return{
        ...state,
        nobelprofileData:{
          ...state.nobelprofileData,
          profession: [payload,...state.nobelprofileData.user.experiences],
          user:{
            ...state.nobelprofileData.user,
          profile:{
            ...state.nobelprofileData.user.profile,
        intro_video: payload.intro_video,
          }
        }
        }
      }
    case UPLOAD_RESUME_DATA:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          user:{
            ...state.nobelprofileData.user,
            profile:{
              ...state.nobelprofileData.user.profile,
          resume_name: payload.resume_name,
          resume_url: payload.resume_url,
          resume_date:payload.resume_date
            }
          }
        },
      };
    case PUBLISH_AS_PUBLIC:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          // worker: {
          //   ...state.nobelprofileData.worker,
            is_public: true,
          // },
        },
      };
    
      case ADD_BUSINESS_PAGE:
      return {
        ...state,
        nobelprofileData: {
          ...state.nobelprofileData,
          Pages : [
            {
              id : payload?.id,
              name : payload?.name,
              email : payload?.email,
              phone : payload?.phone,
              whatsapp: payload?.whatsapp,
              page_url : payload?.page_url
            },
            ...state.nobelprofileData.Pages
          ]
         
        },
      };
      case REMOVE_EXPERIENCE:
        const deleteExperience = state?.nobelprofileData?.user?.experiences?.filter((ele) => ele?.id !== payload);
      return {
        ...state,
        nobelprofileData : {
          ...state.nobelprofileData,
          user : {
            ...state?.nobelprofileData?.user, experiences : deleteExperience
          }
        }
      }
      case REMOVE_EDUCATION:
        const deleteEducation = state?.nobelprofileData?.user?.educations?.filter((ele) => ele?.id !== payload);
      return {
        ...state,
        nobelprofileData : {
          ...state.nobelprofileData,
          user : {
            ...state?.nobelprofileData?.user, educations : deleteEducation
          }
        }
      }
      case REMOVE_LICENSE:
        const deleteLicense = state?.nobelprofileData?.user?.license_certifications?.filter((ele) => ele?.id !== payload);
      return {
        ...state,
        nobelprofileData : {
          ...state.nobelprofileData,
          user : {
            ...state?.nobelprofileData?.user, license_certifications : deleteLicense
          }
        }
      }
      case REMOVE_LANGUAGE:
        const deleteLanguage = state?.nobelprofileData?.user?.languages?.filter((ele) => ele?.id !== payload);
      return {
        ...state,
        nobelprofileData : {
          ...state.nobelprofileData,
          user : {
            ...state?.nobelprofileData?.user, languages : deleteLanguage
          }
        }
      }

      case GET_SEARCH_HISTORY:
        return{
          ...state, searchHistory : payload
        }
    default:
      return state;
  }
};

export default profile;
