import { authApi, setSession } from "../../api/authAPI";
import { initPageLoad, stopPageLoad } from "../loader/loaderActions";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  INIT_SUCCESS,
  INIT_FAIL,
  UPDATE_USER_DATA,
  TOKEN_SUCCESS,
  LOGIN_NP_SUCCESS,
  USER_AFTER_NP_LOGIN,
  INITIALIZE_THE_PAGE_LOADER,
  INIT_WT_SUCCESS,
} from "../types";

export const register = (signUpData) => (dispatch) => {
  dispatch(initPageLoad());
  return authApi.register(signUpData).then(
    (data) => {
    
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: data },
      });


      dispatch({
        type: UPDATE_USER_DATA,
        payload: data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: REGISTER_FAIL,
      });
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const login = (username, password, rememberMe) => (dispatch) => {
  return authApi.login(username, password, rememberMe).then(
    (data) => {
      dispatch(initPageLoad());
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      setSession(data?.data);
      dispatch(stopPageLoad());
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      // const message =
      //   (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

// export const login = (username, password) => (dispatch) => {

//       dispatch({
//         type: LOGIN_SUCCESS,
//       });
//     }

export const loginOnNP =
  (username, password, registration_type) => (dispatch) => {
    return authApi.loginWithNP(username, password, registration_type).then(
      (data) => {
        dispatch({
          type: LOGIN_NP_SUCCESS,
          payload: { user: data },
        });

        return Promise.resolve(data);
      },
      (error) => {
        console.log(error);
        // const message =
        //   (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        dispatch({
          type: LOGIN_FAIL,
        });
        return Promise.reject(error);
      }
    );
  };

export const loadUser = () => {
  return async (dispatch) => {
    let user = null;
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      user = await authApi.getuserbytoken();
    }
    if (user) {
      dispatch(initPageLoad());
      dispatch({
        type: INIT_SUCCESS,
        payload: { user: user.data },
      });
      dispatch(stopPageLoad());
    } else {
      dispatch({
        type: INIT_FAIL,
      });

      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: "Token expired or Not found",
      // });
    }
  };
};

export const loadUserWTLogin = () => {
  return async (dispatch) => {
    let user = null;
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      user = await authApi.getuserbytoken();
    }
    if (user) {
      dispatch(initPageLoad());
      dispatch({
        type: INIT_WT_SUCCESS,
        payload: { user: user.data },
      });
      dispatch(stopPageLoad());
    } else {
      dispatch({
        type: INIT_FAIL,
      });

      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: "Token expired or Not found",
      // });
    }
  };
};

export const getUserAfterNPLogin = (token) => (dispatch) => {
  return authApi.getNPuserbytoken(token).then(
    (data) => {
      dispatch({
        type: USER_AFTER_NP_LOGIN,
        payload: { user: data },
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getPublicToken = () => (dispatch) => {
  return authApi.getpublictoken().then(
    (data) => {
      dispatch({
        type: TOKEN_SUCCESS,
        // payload: { user: data },
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
    }
  );
  // return async (dispatch) => {

  //     dispatch({
  //       type: TOKEN_SUCCESS,
  //       // payload: { user: user },
  //     });
  //   }
};

export const logout = () => (dispatch) => {
  return authApi.logout().then(
    (response) => {
      dispatch(initPageLoad());
      dispatch({
        type: LOGOUT,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: LOGOUT,
      });
      return Promise.reject();
    }
  );
};

export const upDateUserData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_DATA,
    payload: data,
  });
};


export const providerRegister = (type, token) => (dispatch) => {
  dispatch(initPageLoad());
  return authApi.providerSignup(type, token).then(
    (data) => {
    
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: data },
      });


      dispatch({
        type: UPDATE_USER_DATA,
        payload: data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: REGISTER_FAIL,
      });
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};




export const seekerRegister = (signUpData) => (dispatch) => {
  dispatch(initPageLoad());
  return authApi.seekerSignup(signUpData).then(
    (data) => {
    
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: data },
      });


      dispatch({
        type: UPDATE_USER_DATA,
        payload: data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      dispatch({
        type: REGISTER_FAIL,
      });
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};
