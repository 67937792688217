import React, { useEffect, useState } from "react";
import backArrow from "../../assets/img/backArrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import "./ManageWorker.scss";
import HeaderLogo from "../Header/header";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyWorker, getWorker } from "../../store/provider/ProviderAction";
import DefaultIcon from "../../assets/img/default-icon-Profile.png";
import EditIcon from "../../assets/img/blackedit.svg";
import PaymentModel from "../paymentModel/PaymentModel";
import { Input } from "reactstrap";
import PaymentSuccessModel from "../paymentModel/PaymentSuccessModel";
import EditInfo from "../WorkerPoolProfilePages/Componenets/EditInfo";
import BasicInformation from "../WorkerPoolFormPages/BasicInformation/BasicInformation";
import BasicInformationContainer from "../WorkerPoolFormPages/BasicInformation/BasicInformationContainer";
const ManageWorker = (props) => {
  const {
    handleFormSubmit,
    getRegionOfExperience,
    errorEmail,
    setErrorEmail,
    errorFirstName,
    setErrorFirstName,
    errorLastName,
    setErrorLastName,
    setErrorPassword,
    errorPhoneNumber,
    errorMessage,
    setPhoneCount,
    checkSignUpValidation,
    basicForm,
    setBasicForm,
    errorPassword,
    submitSignUpData,
    user,
    location,
    today,
    errorWhatsappNumber,
    countryList,
    experienceRegion,
    religionList,
    errorAbout,
    setErrorAbout,
    errorHeadline,
    setErrorHeadline,
    errorDob,
    setErrorDob,
    errorExp,
    setErrorExp,
    errorPrefCountry,
    setErrorPrefCountry,
    errorNationality,
    setErrorNationality,
    errorROE,
    setErrorROE,
    errorJobType,
    setErrorJobType,
    setCountinue,
    isProfilePage,
    setProfileField,
    isPageLoading,
    handleProfession,
    selectedCategory,
    setSelectedCategory,
    catergoryList,
    professionList,
    errorProfession,
    setErrorProfession,
    errorCategory,
    setErrorCategory,
    askNobelModal,
    setAskNobelModal,
    agency,
    worker, 
    setWorker,
    activePage,
    setActivePage
  } = props;
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [skip, setSkip] = useState(0);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const { manageWorkers } = useSelector((state) => state.provider);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();
  const [successModel, setSuccessModel] = useState(false);


  const { id } = useParams();
  useEffect(() => {
    dispatch(getCompanyWorker({ agency_id: agency, limit, skip }));
  }, []);

  const [workerId, setWorkerId] = useState(0)

  useEffect(() => {
  const token = localStorage.getItem("accessToken");
    if(activePage == "editWorker" && workerId)
    dispatch(
      getWorker({ id: workerId ,token})
    ).then((res) => {
      if(res?.success) setWorker(res?.data)
    });
  }, [activePage]);

  function remainingTime(dateString) {
    var today = new Date();
    var publishDate = new Date(dateString);
    const diffTime = Math.abs(publishDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // var timeLeft = 30 -diffDays;
    // console.log("timeLeft",diffDays);
    return diffDays;
  }
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);

  const handleRowClick = (rowId) => {
    const isSelected = selectedRows.includes(rowId);

    if (isSelected) {
      // If the row is already selected, remove it from the selection
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      // If the row is not selected, add it to the selection
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const handleCheckboxClick = () => {
    selectedRows.length == manageWorkers.length
      ? setSelectedRows([])
      : setSelectedRows(manageWorkers?.map((worker) => worker.id));
  };

  const searchWorker = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length >= 3) {
      dispatch(
        getCompanyWorker({ search: e.target.value, agency_id: agency, limit, skip })
      );
    } else {
      dispatch(getCompanyWorker({ agency_id: agency, limit, skip }));
    }
  };


  return (
    <>
      {/* <HeaderLogo /> */}
      <div className="">
        <div className="container">
          <div className="workerpool-default-form-page">
            <div
              className="back-btn d-flex align-items-start"
              onClick={() => ["addWorker", "editWorker"].includes(activePage) ? setActivePage("") :  setProfileField("profile")}
            >
              <img src={backArrow} alt="Icon" />
              <h2 className={["addWorker", "editWorker"].includes(activePage)?"d-none":"mx-2"} >Manage Workers</h2>
            </div>
          </div>

          {activePage == "addWorker" ? (
            <BasicInformationContainer
            agency={agency}
          />
          ) : activePage == "editWorker"  ? (
            <EditInfo 
            submitSignUpData={submitSignUpData}
                  user={worker}
                  handleFormSubmit={handleFormSubmit}
                  checkSignUpValidation={checkSignUpValidation}
                  errorEmail={errorEmail}
                  errorPassword={errorPassword}
                  setErrorPassword={setErrorPassword}
                  setErrorEmail={setErrorEmail}
                  errorFirstName={errorFirstName}
                  setErrorFirstName={setErrorFirstName}
                  errorLastName={errorLastName}
                  setErrorLastName={setErrorLastName}
                  errorPhoneNumber={errorPhoneNumber}
                  errorMessage={errorMessage}
                  setPhoneCount={setPhoneCount}
                  basicForm={basicForm}
                  setBasicForm={setBasicForm}
                  today={today}
                  countryList={countryList}
                  religionList={religionList}
                  experienceRegion={experienceRegion}
                  errorWhatsappNumber={errorWhatsappNumber}
                  errorAbout={errorAbout}
                  setErrorAbout={setErrorAbout}
                  errorHeadline={errorHeadline}
                  setErrorHeadline={setErrorHeadline}
                  errorDob={errorDob}
                  setErrorDob={setErrorDob}
                  errorExp={errorExp}
                  setErrorExp={setErrorExp}
                  errorPrefCountry={errorPrefCountry}
                  setErrorPrefCountry={setErrorPrefCountry}
                  errorNationality={errorNationality}
                  setErrorNationality={setErrorNationality}
                  errorROE={errorROE}
                  setErrorROE={setErrorROE}
                  errorJobType={errorJobType}
                  setErrorJobType={setErrorJobType}
                  getRegionOfExperience={getRegionOfExperience}
                  setCountinue={setCountinue}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  isProfilePage={true}
                  setProfileField={setProfileField}
                  catergoryList={catergoryList}
                  professionList={professionList}
                  errorCategory={errorCategory}
                  setErrorCategory={setErrorCategory}
                  handleProfession={handleProfession}
                  errorProfession={errorProfession}
                  setErrorProfession={setErrorProfession}
                  worker={worker}
                  />
          ) : (
            <>
              <div className="">
                <div className="d-flex justify-content-between">
                  <div className="">
                    <Input
                      type="text"
                      name="search"
                      value={search}
                      placeholder="Search"
                      onChange={(e) => searchWorker(e)}
                    />
                  </div>
                  <div className="">
                    {selectedRows.length > 0 ? (
                      <>
                        <button
                          className="public_btn py-2 px-4"
                          onClick={() => {
                            setIsModelOpen(!isModelOpen);
                            setAction("public");
                          }}
                        >
                          Make Pulic
                        </button>
                        <button
                          className="delete_btn py-2 px-4 mx-2 manage"
                          onClick={() => {
                            setIsModelOpen(!isModelOpen);
                            setAction("delete");
                          }}
                        >
                          Delete
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="add_btn py-2 px-4 manage"
                          onClick={() =>
                            // navigate("/worker/basic-info", {
                            //   state: {
                            //     id,
                            //   },
                            // })
                            setActivePage("addWorker")
                          }
                        >
                          Add Employee
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {!manageWorkers.length ? (
                <>
                  <div className="mt-4 d-flex justify-content-center">
                    <h2 className="">No Data Found</h2>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-5 table_style ">
                    <table className="w-100 p-5">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              className="checkbox"
                              onClick={handleCheckboxClick}
                            />
                          </th>
                          <th>Employee Name</th>
                          <th>Headline</th>
                          <th>Location</th>
                          <th>Contact</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {manageWorkers.map((ele, index) => {
                          return (
                            <>
                              <tr className="p-3" key={ele.id}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={selectedRows.includes(ele.id)}
                                    onClick={() => handleRowClick(ele.id)}
                                  />
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={
                                        ele?.user?.profile_img || DefaultIcon
                                      }
                                      className="image_icon"
                                    />
                                    <p className="name mx-3">
                                      {ele?.user?.first_name}
                                      {ele?.user?.last_name}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  {ele?.profession.join(", ") || "headline"}
                                </td>
                                <td>{ele?.location || "location"}</td>
                                <td>{ele?.user?.phone || "phone"}</td>
                                <td>
                                  {ele?.is_public ? (
                                    <>
                                      <div className="">
                                        <p className="public">Public</p>
                                        <p className="public_date">
                                          {ele?.is_public_date
                                            ? remainingTime(ele?.is_public_date)
                                            : "30"}{" "}
                                          days remaining
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    <p>Private</p>
                                  )}
                                </td>
                                <td>
                                  <img src={EditIcon}  onClick={()=>{setWorkerId(ele?.id); setActivePage("editWorker")}}/>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {isModelOpen && (
        <PaymentModel
          worker={manageWorkers}
          selectedWorker={selectedRows}
          isOpen={isModelOpen}
          setIsModelOpen={setIsModelOpen}
          action={action}
          setSelectedRows={setSelectedRows}
          successModel={successModel}
          setSuccessModel={setSuccessModel}
        />
      )}

      {successModel && (
        <PaymentSuccessModel
          successModel={successModel}
          setSuccessModel={setSuccessModel}
        />
      )}
    </>
  );
};

export default ManageWorker;
