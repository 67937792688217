import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React, { useState, useCallback } from "react";
import getCroppedImg from "../../../core/ImageCrop/cropImage";
import Cropper from "react-easy-crop";
import "../../../core/ImageCrop/background.scss";
import Loader from "../../../core/Loaders/ComponentLoader";
import { useSelector } from "react-redux";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";

const ProfilePhotoModal = (props) => {
  const {
    modal,
    toggle,
    title,
    handleBannerPicture,
    editProfileImage,
    handleRemovePicture,
  } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadImage, setUploadedImage] = useState(
    editProfileImage ? editProfileImage : ""
  );
  const { isLoading } = useSelector((state) => state.loader);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = async () => {
    try {
      if (uploadImage === "") {
        handleRemovePicture("profile");
      } else {
        const croppedImage = await getCroppedImg(
          uploadImage,
          croppedAreaPixels
        );
        handleBannerPicture(croppedImage);
      }
      // toggle();
    } catch (e) {
      console.error(e);
    }
  };

  //   const onClose = useCallback(() => {
  //     setCroppedImage(null)
  //   }, [])

  const onChangePicture = (e) => {
    var fileExtension = e.target.value.split(".").pop();
    let ImageValid = /^(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
    if (ImageValid.test(fileExtension)) {
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
    } else {
      alert("Please upload only file types jpg/jpeg/png/gif/JPG/JPEG/PNG/GIF");
    }
  };

  const onRemovePicture = () => {
    setUploadedImage("");
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  return (
    <div>
      {isLoading && <AppLoader />}

      <Modal
        size="md"
        isOpen={modal}
        toggle={toggle}
        className="modal-style image-cropper"
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader
          style={{ border: "none", marginTop: "15px" }}
          toggle={toggle}
        >
          {title}
        </ModalHeader>
        <ModalBody>
          <div>
            <div style={{ height: "500px", width: "100%" }}>
              <Cropper
                image={uploadImage}
                crop={crop}
                zoom={zoom}
                aspect={3 / 2}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                cropSize={{ width: 450, height: 450 }}
                onZoomChange={setZoom}
                objectFit="horizontal-cover"
                cropShape="round"
                style={{
                  containerStyle: {
                    //width: "100%",
                    margin: "20px 10px 10px 10px",
                    height: "500px",
                    background:
                      "linear-gradient(117.14deg, #652D92 0%, #2E3192 31.21%, #2353A8 66.87%, #0D95D5 93.36%)",
                    padding: 0,
                    boxShadow: "2px 2px 4px rgba(171, 190, 209, 0.6)",
                    border: "2px solid #FFFFFF",
                  },
                }}
              />
            </div>
            <p className="mb-2 fs-6">
              * Allowed only file types jpg/jpeg/png/gif/JPG/JPEG/PNG/GIF
            </p>
            <div className="sub-heading-slider">
              <span>Zoom</span>
              <span>{zoom}</span>
            </div>
            <div className="controls">
              <input
                type="range"
                value={zoom}
                min={1}
                max={4}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
              />
            </div>
          </div>

          <div className="buttons">
            {editProfileImage && (
              <span onClick={onRemovePicture}>Remove Photo</span>
            )}
            <div className="btns">
              <input
                type="file"
                id="profile_pic"
                name="profile_pic"
                accept="img/*"
                style={{ display: "none" }}
                onChange={onChangePicture}
                onClick={onInputClick}
              />
              <label htmlFor="profile_pic">
                <div className="btn btn-blue transparent">
                  {editProfileImage ? "Change Photo" : "Add Photo"}
                </div>
              </label>
              {uploadImage === editProfileImage ? (
                <div className="btn btn-blue disabled-btn-blue">Apply</div>
              ) : (
                <div className="btn btn-blue" onClick={showCroppedImage}>
                  Apply
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProfilePhotoModal;
