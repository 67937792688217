import { Route, Routes } from "react-router-dom";
import routeNames from "./routeNames";
import WorkerPoolLogin from "../pages/login/WorkerPoolLogin";
import DashboardContainer from "../pages/dashboard/WorkerDashboardContainer";
import WorkerListContainer from "../pages/WorkerSearchList/WorkerListContainer";
import WorkerProfileContainer from "../pages/ProfilePage/workerprofile/WorkerProfileContainer";
import ChooseAccountType from "../pages/ChooseAccountType/ChooseAccountType";
import BasicInformation from "../pages/WorkerPoolFormPages/BasicInformation/BasicInformation";
import Education from "../pages/WorkerPoolFormPages/Education/Education";
import WorkExperience from "../pages/WorkerPoolFormPages/WorkExperience/WorkExperience";
import Licence from "../pages/WorkerPoolFormPages/Licence/Licence";
import Languages from "../pages/WorkerPoolFormPages/Languages/Languages";
import ModalComponents from "../pages/ModalComponents/ModalComponents";
import BasicInformationContainer from "../pages/WorkerPoolFormPages/BasicInformation/BasicInformationContainer";
import ProfileResume from "../pages/WorkerPoolProfilePages/Resume/Resume";
import ProfileSetting from "../pages/WorkerPoolProfilePages/Settings/Settings";
import ChangePassword from "../pages/WorkerPoolProfilePages/ChangePassword/ChangePassword";
import WorkerPoolUserProfileContainer from "../pages/WorkerPoolProfilePages/WorkerPoolUserProfileContainer";
import PrivateRoute from "./PrivateRoute";
import AddJobDetails from "../pages/WorkerPoolFormPages/AddJobDetails/AddJobDetails";
import JobPreview from "../pages/WorkerPoolFormPages/JobPreview/JobPreview";
import CreateProvider from "../pages/CreateProvider/CreateProvider";
import ManageWorker from "../pages/ManageWorkers/ManageWorker";
import JobListContainer from "../pages/WorkerJobSearchList/jobListContainer";
import JobDetailPage from "../pages/JobDetailPage/JobDetailPage";
import  CreateSeeker  from "../pages/CreateSeeker/CreateSeeker";
import AddSeekerContainer from "../pages/WorkerPoolFormPages/AddSeeker/AddSeekerContainer";
import SearchHistory from "../pages/SearchHistory/SearchHistory";


const Router = () => (
    <Routes>
         {/* worker pool */}
    <Route path={routeNames.LOGIN} element={<WorkerPoolLogin/>} />
    
    <Route
      path={routeNames.DASHBOARD}
      element={<DashboardContainer />}
    />
    <Route path={routeNames.WORKERLIST} element={<PrivateRoute><WorkerListContainer /></PrivateRoute>} />
    <Route
      path={routeNames.WORKERPROFILE}
      element={<PrivateRoute><WorkerProfileContainer /></PrivateRoute>}
    />

<Route
      path={routeNames.SEARCHHISTORY}
      element={<PrivateRoute><SearchHistory /></PrivateRoute>}
    />
    <Route
      path={routeNames.ACCOUNTTYPE}
      element={<ChooseAccountType />}
    />
    <Route
      path={routeNames.BASICINFO}
      element={<BasicInformationContainer />}
    />
    <Route
    path={routeNames.CREATESEEKERLOGIN}
    element={<CreateSeeker />}
    />

<Route
    path={routeNames.ADDSEEKER}
    element={<AddSeekerContainer />}
    />

    {/* <Route
      path={routeNames.EDUCATION}
      element={<Education />}
    /> */}
    {/* <Route
      path={routeNames.WORKEXPERIENCE}
      element={<WorkExperience />}
    />
    <Route
      path={routeNames.LICENCE}
      element={<Licence />}
    />
    <Route
      path={routeNames.LANGUAGES}
      element={<Languages />}
    /> */}
    <Route
      path={routeNames.MODALCOMPONENTS}
      element={<ModalComponents />}
    />
    <Route
      path={routeNames.WORKERPOOLPROFILE}
      element={<PrivateRoute><WorkerPoolUserProfileContainer/></PrivateRoute>}
    />


<Route
      path={routeNames.JOBLIST}
      element={<PrivateRoute><JobListContainer/></PrivateRoute>}
    />

<Route
      path={routeNames.MANAGEWORKER}
      element={<PrivateRoute><ManageWorker/></PrivateRoute>}
    />
    <Route
      path={routeNames.JOBDETAILPAGE}
      element={<PrivateRoute><JobDetailPage/></PrivateRoute>}
    />
    {/* <Route
      path={routeNames.WORKERPOOLPROFILEINFORMATION}
      element={<ProfileBasicInformation />}
    />
    <Route
      path={routeNames.WORKERPOOLPROFILEEDUCATION}
      element={<ProfileEducation />}
    />
    <Route
      path={routeNames.WORKERPOOLPROFILEEXPERIENCE}
      element={<ProfileWorkExperience />}
    />
    <Route
      path={routeNames.WORKERPOOLPROFILELICENCE}
      element={<ProfileLicence />}
    />
    <Route
      path={routeNames.WORKERPOOLPROFILELANGUAGES}
      element={<ProfileLanguages />}
    /> */}
    <Route
      path={routeNames.WORKERPOOLPROFILERESUME}
      element={<ProfileResume />}
    />
    <Route
      path={routeNames.WORKERPOOLPROFILESETTING}
      element={<ProfileSetting />}
    />
    <Route
      path={routeNames.WORKERPOOLPROFILECHANGEPASSWORD}
      element={<ChangePassword />}
    />
    <Route
      path={routeNames.WORKERPOOLADDJOBDETAILS}
      element={<PrivateRoute><AddJobDetails /></PrivateRoute>}
    />
    <Route
      path={routeNames.WORKERPOOLADDJOBPREVIEW}
      element={<JobPreview />}
    />

    <Route path={routeNames.CREATEPROVIDERLOGIN} element={<CreateProvider/>} />








    {/* <Route
      path={routeNames.WORKERJOBDETAIL}
      element={<WorkerJobDetail />}
    /> */}
    {/* <Route
      path={routeNames.WORKERWORKERPROFILEPAGE}
      element={<WorkerProfilePage />}
    /> */}
      
      </Routes>
)
export default Router;