import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, Progress } from "reactstrap";
import "../WorkerPoolDefaultForm.scss";
import edit from "../../../assets/img/edit.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  addlicensedetails,
  getNobelUserProfile,
  getWPUserProfileById,
  removeProfileSubData,
} from "../../../store/profile/ProfileAction";
import DatePickerCustom from "../../../core/DatePicker/DatePickerReact";
import backArrow from "../../../assets/img/backArrow.svg";
import HeaderLogo from "../../Header/header";
import { toast } from "react-toastify";
import Loader from "../../../core/Loaders/ComponentLoader";
import dustbin from "../../../assets/img/dustbin.svg";
import AppLoader from "../../../core/Loaders/appLoader/appLoader";
const Licence = (props) => {
  let { setCountinue, user, isProfilePage, setProfileField, token } = props;
  const { nobelprofileData } = useSelector((state) => state.profile);
  const [errorMessage, setErrorMessage] = useState("");
  const [editLicenseData, setEditLicenseData] = useState();
  const [errorName, setErrorName] = useState("");
  // const [errorOrg, setErrorOrg] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [expData, setExpData] = useState({
    name: "",
    start_date: "",
    end_date: "",
    credential_id: "",
  });
  token = token || localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.loader);
  useEffect(() => {
    let id = isProfilePage ? user?.id : user?.worker?.id;
    if (id) {
      dispatch(getWPUserProfileById(id, token)).then((response) => {
        if (response?.success === false) return;
      });
    }
  }, []);

  const handleSave = () => {
    setProfileField("profile");
  };

  const handleDelete = (id) => {
    dispatch(removeProfileSubData("license-certification", id));
  };

  const checkSignUpValidation = (type) => {
    let formIsValid = true;
    let stringName = /^[^0-9][a-zA-Z0-9\.\,\+\-_\s]+$/;
    if (type === "all" || type === "name") {
      if (!expData.name.trim()) {
        setErrorName("Name is mandatory.");
        formIsValid = false;
        return;
      } else if (!stringName.test(expData?.name)) {
        setErrorName(
          "Name should not start with numbers and can't include special charectors"
        );
        formIsValid = false;
        return;
      } else {
        setErrorName("");
      }
    }
    // if (type === "all" || type === "issuing_org") {
    //   if (!expData.issuing_org.trim()) {
    //     setErrorOrg("Issuing org is mandatory.");
    //     formIsValid = false;
    //   } else if (!stringName.test(expData?.issuing_org)) {
    //     setErrorOrg(
    //       "Name should not start with numbers and can't include special charectors"
    //     );
    //     formIsValid = false;
    //     return;
    //   } else {
    //     setErrorOrg("");
    //   }
    // }

    if (type === "all" || type === "date") {
      if (!expData.start_date || !expData.end_date) {
        setErrorDate("Start Date and End Date is required.");
        formIsValid = false;
        return;
      } else {
        setErrorDate("");
      }
    }

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkSignUpValidation("all")) {
      let expDataformat = {
        license_certifications: [expData],
      };

      dispatch(addlicensedetails(expDataformat, token))
        .then((res) => {
          if (res?.success) {
            {
              isEdit
                ? toast.success("Licence updated Successfully", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  })
                : toast.success("Licence added Successfully", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
            }
            if (editLicenseData) {
              setEditLicenseData({});
              setIsEdit();
            }
            setExpData({
              name: "",
              start_date: "",
              end_date: "",
              credential_id: "",
              // issuing_org: "",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          setErrorMessage(e.response.data.message);
          alert("Something went wrong.");
        });
    }
  };

  return (
    <>
      {isLoading && <AppLoader />}
      {!isProfilePage && <HeaderLogo />}
      <div
        className={
          "education-info-page workerpool-default-form-page" +
          (isProfilePage ? " my-4" : "")
        }
      >
        <div className="container">
          <div className="width-850">
            {isProfilePage && (
              <div className="workerpool-default-form-page">
                <div
                  className="back-btn"
                  onClick={() => setProfileField("profile")}
                >
                  <img src={backArrow} alt="Icon" />
                </div>
              </div>
            )}
            <div className="head progress-box">
              <h3 className="mb-2">Licence</h3>
              {!isProfilePage && <Progress value={80} />}
            </div>
            <form className="form-box">
              {errorMessage && (
                <label className="label-input100 text-danger">
                  {errorMessage}
                </label>
              )}
              <div className="row">
                <FormGroup className="col-sm-12">
                  <Label>Licence Name</Label>
                  <Input
                    type="text"
                    placeholder="Enter licence name"
                    value={expData ? expData.name : ""}
                    maxLength="50"
                    onChange={(e) => {
                      setExpData({ ...expData, name: e.target.value });
                      setErrorMessage("");
                      setErrorName("");
                    }}
                  />
                  {errorName && (
                    <label className="label-input100 text-danger">
                      {errorName}
                    </label>
                  )}
                </FormGroup>
                {/* <FormGroup className="col-sm-6">
                  <Label>Issuing Organization</Label>
                  <Input
                    type="text"
                    placeholder="Enter issuing organization"
                    value={expData ? expData.issuing_org : ""}
                    maxLength="50"
                    onChange={(e) => {
                      setExpData({ ...expData, issuing_org: e.target.value });
                      setErrorMessage("");
                      setErrorOrg("");
                    }}
                  />
                  {errorOrg && (
                    <label className="label-input100 text-danger">
                      {errorOrg}
                    </label>
                  )}
                </FormGroup> */}
              </div>

              <div className="row">
                <FormGroup className="col-sm-5" style={{ display: "grid" }}>
                  <Label>Expiration date</Label>
                  <DatePickerCustom
                    value={expData.end_date ? new Date(expData.end_date) : ""}
                    onChange={(date) => {
                      date
                        ? setExpData({
                            ...expData,
                            end_date: moment(date).format("YYYY-MM-DD"),
                          })
                        : setExpData({ ...expData, end_date: "" });
                      setErrorMessage("");
                    }}
                    placeholder="Select"
                    onClick={() => setErrorDate("")}
                    disabled={expData?.start_date === "" ? true : false}
                    min={
                      expData?.start_date ? new Date(expData?.start_date) : ""
                    }
                    max={new Date()}
                  />
                </FormGroup>
                <FormGroup className="col-sm-5" style={{ display: "grid" }}>
                  <Label>Issue date</Label>
                  <DatePickerCustom
                    value={
                      expData.start_date ? new Date(expData.start_date) : ""
                    }
                    onChange={(date) => {
                      date
                        ? setExpData({
                            ...expData,
                            start_date: moment(date).format("YYYY-MM-DD"),
                            end_date: "",
                          })
                        : setExpData({
                            ...expData,
                            start_date: "",
                            end_date: "",
                          });
                      setErrorMessage("");
                    }}
                    placeholder="Select"
                    max={new Date()}
                  />
                </FormGroup>

                <div className="col-lg-2 col-12 text-center text-lg-end align-self-end mb-3">
                  <div
                    className="btn btn-blue"
                    style={{ padding: "10px 12px", marginTop: "25px" }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {isEdit ? "Save" : "Add Licence"}
                  </div>
                </div>
              </div>
              <div className="row">
                {errorDate && (
                  <label className="text-danger">{errorDate}</label>
                )}
                {/* <FormGroup className="col-sm-6">
                <Label>Credential ID</Label>
                <Input
                  type="text"
                  placeholder=""
                  value={expData?.credential_id}
                  maxLength="50"
                  onChange={(e) =>
                    setExpData({ ...expData, credential_id: e.target.value })
                  }
                />
              </FormGroup> */}
              </div>
              <div className="col-sm-12 mt-5">
                {nobelprofileData?.user?.license_certifications?.length > 0 && (
                  <div className="line mb-1"></div>
                )}
                {nobelprofileData?.user?.license_certifications?.map(
                  (item, index) => (
                    <div className="edit-box" key={index}>
                      <div className="d-flex justify-content-between">
                        <h4>{item?.name}</h4>
                        <div className="">
                          <span
                            className="edit-btn"
                            onClick={() => {
                              setEditLicenseData(item);
                              setExpData({
                                id: item?.id,
                                name: item?.name,
                                start_date: item?.start_date,
                                end_date: item?.end_date ? item?.end_date : "",
                                credential_id: item?.credential_id,
                                issuing_org: item?.issuing_org,
                              });
                              setIsEdit(item.id);
                              setErrorMessage("");
                              window.scrollTo(0, 0);
                            }}
                          >
                            {isEdit != item.id && <img src={edit} alt="Icon" />}
                          </span>
                          <span className="edit-btn mx-3">
                            <img
                              src={dustbin}
                              onClick={() => handleDelete(item.id)}
                            />
                          </span>
                        </div>
                      </div>
                      <p style={{ fontSize: "16px" }}>
                        Valid:{" "}
                        {new Date(item?.end_date).toLocaleString("en-us", {
                          month: "short",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  )
                )}
              </div>
              {isProfilePage ? (
                <div className="text-center my-3">
                  {/* <div className="btn btn-blue" onClick={() => handleSave()}>
                    Save
                  </div> */}
                </div>
              ) : (
                <div className="text-end mt-5 d-flex justify-content-between">
                  <Button
                    className="btn-outline no-bg mx-0 mx-md-4 mb-5"
                    onClick={() => setCountinue(2)}
                  >
                    Back
                  </Button>
                  <div className="d-flex skip">
                    <h5
                      className="skip_btn mb-5 mx-4 me-3"
                      onClick={() => setCountinue(4)}
                    >
                      Skip
                    </h5>
                    <Button
                      className="btn-blue mb-5 mx-3 me-0"
                      onClick={() => setCountinue(4)}
                    >
                      Save & Continue
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Licence;
