import { combineReducers } from "redux";
import dashboard from "./dashboard/dashboardReducer";
import profile from "./profile/ProfileReducer"
import auth from "./auth/authReducers"
import loader from "./loader/loaderReducers"
import provider from "./provider/ProviderReducer"
import job from "./job/jobReducer"
export default combineReducers({
    dashboard,
    auth,
    profile,
    loader,
    provider,
    job
})