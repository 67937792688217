import { toast } from "react-toastify";
import { dashboardApi } from "../../api/dashboardApi";
import { jobApi } from "../../api/jobApi";
import { initPageLoad, stopPageLoad } from "../loader/loaderActions";
import {
  FAIL_GET_WORKER_LIST,
  GET_JOB_LIST,
  GET_JOB_DETAIL,
  APPLY_JOB,
  APPLY_JOB_DETAIL,
  SAVED_JOB,
  GET_MY_JOB_LIST,
  UPDATE_JOB,
  GET_JOB_APPLICANT,
  UPDATE_JOB_DETAIL,
} from "../types";

export const getJobList = (params) => (dispatch, getState) => {
  const { jobList } = getState().job;
  dispatch(initPageLoad());
  console.log(jobList?.jobList && params?.skip && params?.limit, "jfjhkjk");
  return jobApi.getJobs(params).then(
    (response) => {
      dispatch({
        type: GET_JOB_LIST,
        payload: {
          jobList:
            jobList?.jobList && params?.skip && params?.limit
              ? [...jobList?.jobList, ...response.data]
              : response.data,
          totalCount: response.totalItem,
        },
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      toast.error(error.response.data.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getJobDetail = (id) => (dispatch) => {
  dispatch(initPageLoad());
  return jobApi.getJobDetail(id).then(
    (response) => {
      dispatch({
        type: GET_JOB_DETAIL,
        // payload: response
        payload: response?.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error?.response?.data?.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const ApplyJob = (id) => (dispatch) => {
  return jobApi.applyJob(id).then(
    (response) => {
      console.log(response?.data);
      dispatch({
        type: APPLY_JOB,
        payload: response?.data,
      });
      console.log("publish res", response);
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.resolve(error);
    }
  );
};

export const ApplyJobInDetail = (id) => (dispatch) => {
  return jobApi.applyJob(id).then(
    (response) => {
      console.log(response?.data);
      dispatch({
        type: APPLY_JOB_DETAIL,
        payload: response?.data,
      });
      console.log("publish res", response);
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.resolve(error);
    }
  );
};

export const SavedJob = (id) => (dispatch) => {
  return jobApi.savedJob(id).then(
    (response) => {
      dispatch({
        type: SAVED_JOB,
        payload: id,
      });
      console.log("publish res", response);
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.resolve(error);
    }
  );
};

export const getMyJobs = (params) => (dispatch, getState) => {
  const { myJob } = getState().job;

  dispatch(initPageLoad());
  return jobApi.getJobs(params).then(
    (response) => {
      dispatch({
        type: GET_MY_JOB_LIST,
        payload:
          myJob && params?.skip && params?.limit
            ? [...myJob, ...response.data]
            : response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error.response.data.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const UpdateJob = (id, data) => (dispatch, getState) => {
  return jobApi.updateJob(id, data).then(
    (response) => {
      dispatch({
        type: UPDATE_JOB,
        payload: id,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error.response.data.message);
      return Promise.reject(error);
    }
  );
};

export const UpdateJobDetail = (id, data) => (dispatch, getState) => {
  return jobApi.updateJob(id, data).then(
    (response) => {
      console.log(data);
      dispatch({
        type: UPDATE_JOB_DETAIL,
        payload: data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error.response.data.message);
      return Promise.reject(error);
    }
  );
};

export const getApplicants = (params) => (dispatch, getState) => {
  const { applicant } = getState().job;
  dispatch(initPageLoad());
  return jobApi.getJobApplicant(params).then(
    (response) => {
      dispatch({
        type: GET_JOB_APPLICANT,
        // payload: response
        payload: {
          applicant:
            applicant && params?.skip && params?.limit
              ? { ...response.data, data: [...applicant, ...response.data] }
              : response.data,
        },
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error.response.data.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const DeleteJob = (id) => (dispatch, getState) => {
  return jobApi.deleteJob(id).then(
    (response) => {
      dispatch({
        type: UPDATE_JOB,
        payload: id,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error.response.data.message);
      return Promise.reject(error);
    }
  );
};
