import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAllCountries,
  getAllProfession,
  getAllReligion,
  getExperienceRegion,
  getWorkerList,
} from "../../store/dashboard/dashboardAction";
import { useLocation } from "react-router";
import { loadUser } from "../../store/auth/authActions";
import WorkerJobSearchList from "./WorkerJobSearchList";
import { call } from "../../api/apiCall";
import { getJobList } from "../../store/job/jobAction";
import axios from "axios";

function JobListContainer() {
  const dispatch = useDispatch();
  const {
    // jobList,
    //   experienceRegion,
    //   religionList,
    //   catergoryList,
    //   professionList,
    countryList,
  } = useSelector((state) => state.dashboard);
  const { jobList } = useSelector((state) => state.job);

  // const [selectedCategory, setSelectedCategory] = useState("");
  // const [professionError, setProfessionError] = useState([]);
  // const [selectedProfession, setSelectedProfession] = useState("");
  const [selectFilter, setSelectFilter] = useState("");
  //   const [age, setAge] = useState("");
  //   const [gender, setGender] = useState("");
  //   const [experience, setExperience] = useState("");
  //   const [nationality, setNationality] = useState("");
  // const [roe, setRoe] = useState([]);
  // const [employmentType, setEmploymentType] = useState([]);
  // const [createdBy, setCreatedBy] = useState("");
  // const navigate = useNavigate();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { state } = useLocation();
  const [currencyCodes, setCurrencyCodes] = useState({});

  const currencySymbol = async () => {
    try {
      const res = await axios.get(
        `https://api.exchangerate-api.com/v4/latest/USD`
      );
      if (res?.status == 200) {
        setCurrencyCodes(res?.data?.rates);
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(state, "state");

  const [search, setSearch] = useState(state?.search);
  const [location, setLocation] = useState(state?.location);
  const [age, setJobAge] = useState("");
  const [experience, setJobExperience] = useState(state?.experience);
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState(state?.country);
  const [jobType, setJobType] = useState(state?.job_type);
  const [jobFilter, setJobFilter] = useState("");
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  console.log(search, "state?.searchFilter");

  useEffect(() => {
    if (!state?.searchFilter) {
      searchAllJobs();
    }
    // if(state?.searchFilter){
    //   window.onbeforeunload(dispatch(getWorkerList()))
    // }
    getNationality();
    currencySymbol();
    if (isLoggedIn) {
      dispatch(loadUser());
    }
  }, []);

  // useEffect(()=>{
  //   searchAllJobs()
  // }, [])

  const searchJobs = (e) => {
    setSkip(0);
    setSelectFilter("");
    e.preventDefault();

    dispatch(
      getJobList({
        search: search || undefined,
        location: location || undefined,
        experience: experience || undefined,
        age: age || undefined,
        gender: gender || undefined,
        nationality: nationality || undefined,
        employment_type: jobType || undefined,
        wp_type: true,
        limit,
        skip,
      })
    );
  };

  //   const getRegionOfExperience = () => {
  //     dispatch(getExperienceRegion());
  //   };

  const getNationality = () => {
    dispatch(getAllCountries());
  };
  const getReligion = () => {
    dispatch(getAllReligion());
  };
  //   const getCategory = () => {
  //     dispatch(getAllProfession());
  //   };
  //   const getProfession = () => {
  //     dispatch(
  //       getAllProfession({ category: selectedCategory ? selectedCategory : null })
  //     );
  //   };

  const searchAllJobs = () => {
    dispatch(getJobList({ limit, skip, wp_type: true }));
  };

  const searchSavedJobs = () => {
    setSkip(0);
    dispatch(getJobList({ filter: "saved", limit, skip, wp_type: true }));
  };

  const searchAppliedJobs = () => {
    setSkip(0);
    dispatch(getJobList({ filter: "applied", limit, skip, wp_type: true }));
  };

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const currentHeight =
      document.documentElement.scrollTop + window.innerHeight;
    if (
      currentHeight + 1 >= scrollHeight &&
      jobList?.totalCount > jobList?.jobList?.length
    ) {
      console.log();
      setSkip(skip + 10);
    }
  };

  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    dispatch(
      getJobList({
        search: search && search,
        location: location && location,
        experience: experience && experience,
        age: age && age,
        gender: gender && gender,
        nationality: nationality && nationality,
        employment_type: jobType && jobType,
        filter: selectFilter || null,
        wp_type: true,
        limit,
        skip,
      })
    );

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [skip]);

  const [locationList, setLocationList] = useState([]);

  const getLocation = (e) => {
    console.log(e);
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/location?search=${e}`,
            null,
            null,
            null
          );
          if (res.data.success) {
            setLocationList(res?.data?.data);
          }
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };
  return (
    <>
      <WorkerJobSearchList
        jobList={jobList}
        type="job"
        setAge={setJobAge}
        setGender={setGender}
        setExperience={setJobExperience}
        setNationality={setNationality}
        searchJobs={searchJobs}
        user={user}
        searchAllJobs={searchAllJobs}
        setSearch={setSearch}
        setLocation={setLocation}
        setJobType={setJobType}
        nationalityList={countryList}
        setJobFilter={setJobFilter}
        searchSavedJobs={searchSavedJobs}
        searchAppliedJobs={searchAppliedJobs}
        isLoggedIn={isLoggedIn}
        skip={skip}
        locationList={locationList}
        getLocation={getLocation}
        location={location}
        search={search}
        jobAge={age}
        jobGender={gender}
        jobExp={experience}
        selectFilter={selectFilter}
        setSelectFilter={setSelectFilter}
        currencyCodes={currencyCodes}
      />
    </>
  );
}

export default JobListContainer;
