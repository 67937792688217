import {
  GET_MANAGEWORKER_LIST,
  UPDATE_MANAGEWORKER_PROFILE,
  GET_CATEGORY_LIST,
  GET_COUNTRY_LIST,
  GET_EXP_REGION,
  GET_PROFESSION_LIST,
  GET_RELIGION_LIST,
  GET_STATIC_DATA,
  GET_WORKER_LIST,
  DELETE_MANAGEWORKER_PROFILE
} from "../types";

const initialState = {
  manageWorkers: [],
};
const provider = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MANAGEWORKER_LIST:
      return {
        ...state,
        manageWorkers: payload,
      };
    case UPDATE_MANAGEWORKER_PROFILE:
      const currentDate = new Date();

      // Create a new date that is 30 days ahead
      const next30Days = new Date(currentDate);
      next30Days.setDate(currentDate.getDate() + 30);

      // Format the date as needed
      const formattedDate = next30Days.toISOString().split("T")[0];
      const data = state.manageWorkers.map((ele) =>
        payload?.ids.includes(ele.id)
          ? { ...ele, is_public: true, is_public_date: formattedDate }
          : ele
      );
      return {
        ...state,
        manageWorkers: data,
      };
    case DELETE_MANAGEWORKER_PROFILE:
      const worker = state.manageWorkers.filter((ele) => !payload.ids.includes(ele.id))
      return {
        ...state,
        manageWorkers: worker,
      };
    case GET_RELIGION_LIST:
      return {
        ...state,
        religionList: payload,
      };
    case GET_CATEGORY_LIST:
      return {
        ...state,
        catergoryList: payload,
      };
    case GET_PROFESSION_LIST:
      return {
        ...state,
        professionList: payload,
      };

    case GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: payload,
      };
    default:
      return state;
  }
};

export default provider;
