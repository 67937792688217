import { call } from "./apiCall";

class JobApi {
  getJobDetail(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            token
              ? `api/v1/job/job-detail/${id}`
              : `api/v1/public/job-detail/${id}`,
            null,
            null,
            token ? token : null
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  getJobs(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            token ? `api/v1/job/job-list` : `api/v1/public/job-list`,
            params,
            null,
            token ? token : null
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }

  applyJob(id) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/apply-job/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  savedJob(id) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/saved-job/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  updateJob(id, data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/job/update-job/${id}`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }

  getJobApplicant(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/job/view-applicant`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }

  postJob(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/job/create`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }

  deleteJob(id, data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/job/remove-job/${id}`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }
}
export const jobApi = new JobApi();
