import React, { useEffect, useState } from "react";
import Dashboard from "./WorkerDashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCountries,
  getAllProfession,
  getAllReligion,
  getExperienceRegion,
  getLandingPageData,
  getWorkerList,
} from "../../store/dashboard/dashboardAction";
import { getJobList } from "../../store/job/jobAction";
import { loadUser, loadUserWTLogin } from "../../store/auth/authActions";
import { useLocation, useNavigate } from "react-router";
import { call } from "../../api/apiCall";

function DashboardContainer() {
  const dispatch = useDispatch();
  const {
    workerList,
    staticData,
    experienceRegion,
    religionList,
    catergoryList,
    professionList,
    countryList,
  } = useSelector((state) => state.dashboard);
  const { jobList } = useSelector((state) => state.job);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { isPageLoading } = useSelector((state) => state.loader);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [professionError, setProfessionError] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [experience, setExperience] = useState("");
  const [nationality, setNationality] = useState("");
  const [roe, setRoe] = useState([]);
  const [employmentType, setEmploymentType] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [publishModal, setPublishModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [searchWorkerText, setSearchWorkerText] = useState("all");
  const [employmentTypeList, setEmploymentTypeList] = useState("");

  //job search

  const [searchJob, setSearchJob] = useState("");
  const [jobExperience, setJobExperience] = useState("");
  const [salary, setJobSalary] = useState("");
  const [jobAge, setJobAge] = useState("");
  const [jobProfessionError, setJobProfessionError] = useState([]);
  const [jobGender, setJobGender] = useState("");
  const [jobSelectedProfession, setJobSelectedProfession] = useState("");
  const [selectedJobCategory, setSelectedJobCategory] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobCountry, setJobCountry] = useState("");
  const [jobLocation, setJoblocation] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [location, setLocation] = useState("");
  const [locationList, setLocationList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getLandingPageData({ title: "workerpool" }));
    dispatch(getWorkerList({ is_public: true, limit, skip }));
    dispatch(getJobList({ limit, skip, wp_type: true }));
    getRegionOfExperience();
    getNationality();
    getReligion();
    getCategory();
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      dispatch(loadUser());
    }
    // if(isLoggedIn)
    // {dispatch(loadUserWTLogin())}
    (async () => {
      try {
        const res = await call("get", "api/v1/employment-type", null, null);
        setEmploymentTypeList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  useEffect(() => {
    getProfession();
  }, [selectedCategory]);

  const searchWorkers = (e) => {
    e.preventDefault();
    if (selectedCategory) {
      !selectedProfession
        ? setProfessionError("Please choose profession")
        : setProfessionError("");
    }
    dispatch(
      getWorkerList({
        profession: selectedProfession && selectedProfession,
        experience: experience && experience,
        age: age && age,
        gender: gender && gender,
        nationality: nationality && nationality,
        experience_region: roe && roe,
        work_type: employmentType && employmentType,
        created_by: createdBy && createdBy,
        limit,
        skip,
      })
    );
    console.log(createdBy);
    navigate("/workers", {
      state: {
        searchFilter: true,
        profession: selectedProfession && selectedProfession,
        experience: experience && experience,
        age: age && age,
        gender: gender && gender,
        nationality: nationality && nationality,
        experience_region: roe && roe,
        created_by: createdBy && createdBy,
        category: selectedCategory && selectedCategory,
      },
    });
  };

  const searchJobs = (e) => {
    e.preventDefault();
    if (selectedJobCategory) {
      !jobSelectedProfession
        ? setJobProfessionError("Please choose profession for this category")
        : setJobProfessionError("");
    }
    dispatch(
      getJobList({
        profession: jobSelectedProfession || undefined,
        experience: jobExperience || undefined,
        age: jobAge || undefined,
        employment_type: jobType || undefined,
        search: searchJob || undefined,
        salary: salary || undefined,
        wp_type: true,
        nationality: jobCountry || undefined,
        location: jobLocation || undefined,
        limit: 10,
        skip: skip,
      })
    );
    navigate("/jobs", {
      state: {
        searchFilter: true,
        profession: jobSelectedProfession || undefined,
        experience: jobExperience || undefined,
        age: jobAge || undefined,
        employment_type: jobType || undefined,
        search: searchJob || undefined,
        country: jobCountry || undefined,
        salary: salary || undefined,
        location: jobLocation || undefined,
      },
    });
  };

  const searchAllJobs = (e) => {
    e.preventDefault();
    dispatch(getJobList({ limit, skip, wp_type: true }));
  };

  const searchFullTimeJob = (e) => {
    e.preventDefault();
    dispatch(
      getJobList({ limit, skip, employment_type: "full-time", wp_type: true })
    );
  };
  const searchPartTimeJob = (e) => {
    e.preventDefault();
    dispatch(
      getJobList({ employment_type: "part-time", wp_type: true, limit, skip })
    );
    // .then((res)=>{
    //   console.log("res", res);
    // })
  };

  const searcAllhWorkers = (e) => {
    e.preventDefault();
    dispatch(getWorkerList({ limit, skip }));
  };

  const searchWorkersIndependent = (e) => {
    e.preventDefault();
    dispatch(
      getWorkerList({ created_by: "independent", limit, skip, is_public: true })
    );
  };
  const searchWorkersAgency = (e) => {
    e.preventDefault();
    dispatch(
      getWorkerList({ created_by: "agency", limit, skip, is_public: true })
    );
    // .then((res)=>{
    //   console.log("res", res);
    // })
  };

  const getRegionOfExperience = () => {
    dispatch(getExperienceRegion());
  };

  const getNationality = () => {
    dispatch(getAllCountries());
  };
  const getReligion = () => {
    dispatch(getAllReligion());
  };
  const getCategory = () => {
    dispatch(getAllProfession());
  };
  const getProfession = () => {
    dispatch(
      getAllProfession({ category: selectedCategory ? selectedCategory : null })
    );
  };
  const { state } = useLocation();

  useEffect(() => {
    if (state?.signup) {
      setPublishModal(true);
    }
  }, []);

  const getLocation = (e) => {
    console.log(e);
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/location?search=${e}`,
            null,
            null,
            null
          );
          if (res.data.success) {
            setLocationList(res?.data?.data);
          }
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };

  return (
    <Dashboard
      workerList={workerList}
      staticData={staticData}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      professionError={professionError}
      setSelectedProfession={setSelectedProfession}
      setAge={setAge}
      setGender={setGender}
      setExperience={setExperience}
      setNationality={setNationality}
      experienceRegion={experienceRegion}
      setEmploymentType={setEmploymentType}
      createdBy={createdBy}
      setCreatedBy={setCreatedBy}
      countryList={countryList}
      religionList={religionList}
      catergoryList={catergoryList}
      professionList={professionList}
      searchWorkers={searchWorkers}
      searcAllhWorkers={searcAllhWorkers}
      searchWorkersIndependent={searchWorkersIndependent}
      searchWorkersAgency={searchWorkersAgency}
      setRoe={setRoe}
      user={user}
      publishModal={publishModal}
      setPublishModal={setPublishModal}
      paymentModal={paymentModal}
      setPaymentModal={setPaymentModal}
      searchWorkerText={searchWorkerText}
      setSearchWorkerText={setSearchWorkerText}
      isPageLoading={isPageLoading}
      employmentTypeList={employmentTypeList}
      setSearchJob={setSearchJob}
      setJobExperience={setJobExperience}
      setJobSalary={setJobSalary}
      setJobAge={setJobAge}
      setJobProfessionError={setJobProfessionError}
      setJobGender={setJobGender}
      setJobSelectedProfession={setJobSelectedProfession}
      setSelectedJobCategory={setSelectedJobCategory}
      jobProfessionError={jobProfessionError}
      setJobType={setJobType}
      jobList={jobList}
      searchJobs={searchJobs}
      jobType={jobType}
      searchAllJobs={searchAllJobs}
      searchFullTimeJob={searchFullTimeJob}
      searchPartTimeJob={searchPartTimeJob}
      skip={skip}
      setSkip={setSkip}
      setJobCountry={setJobCountry}
      locationList={locationList}
      getLocation={getLocation}
      location={jobLocation}
      setLocation={setJoblocation}
    />
  );
}

export default DashboardContainer;
