const routeNames = {
    LOGIN: "/login",
     DASHBOARD: "/",
     WORKERLIST:"/workers",
     WORKERPROFILE:"/workers/profile/:id",
    ACCOUNTTYPE: "/worker/account-type",
    BASICINFO: "/worker/basic-info",
    EDUCATION: "/worker/education",
    WORKEXPERIENCE: "/worker/work-experience",
    LICENCE: "/worker/licence",
    LANGUAGES: "/worker/languages",
    MODALCOMPONENTS: "/worker/modal-components",
    WORKERPOOLPROFILE: "/worker/profile-dashboard",
    WORKERPOOLPROFILEINFORMATION: "/worker/profile-information",
    WORKERPOOLPROFILEEDUCATION: "/worker/profile-education",
    WORKERPOOLPROFILEEXPERIENCE: "/worker/profile-work-experience",
    WORKERPOOLPROFILELICENCE: "/worker/profile-licence",
    WORKERPOOLPROFILELANGUAGES: "/worker/profile-languages",
    WORKERPOOLPROFILERESUME: "/worker/profile-resume",
    WORKERPOOLPROFILESETTING: "/worker/profile-setting",
    WORKERPOOLPROFILECHANGEPASSWORD: "/worker/change-password",
    WORKERPOOLADDJOBDETAILS: "/add-job-details",
    WORKERPOOLADDJOBPREVIEW: "/worker/job-preview",

    CREATEPROVIDERLOGIN: "/worker/create-provider",
    PROVIDERPROFILE: "/provider/profile-dashboard",
    MANAGEWORKER: "/provider/manage-worker/:id",
    JOBLIST: "/jobs",
    JOBDETAILPAGE : "/job/:id",
    CREATESEEKERLOGIN: "worker/create-seeker",
    ADDSEEKER: "worker/add-seeker",
    SEARCHHISTORY: "/search-history"
    
    


    // CHECKAUTO: "/check/autocomplete",
    // WORKERDASHBOARDNEW: "/worker/dashboard-new",
    // WORKERSEARCHLIST: "/worker/Worker-Search-List",
    // WORKERJOBSEARCHLIST: "/worker/Worker-Job-Search-List",
    // WORKERWORKERPROFILEPAGE: "/worker/Profile-Page",
    // WORKERJOBDETAIL: "/worker/Job-Detail",
}

export default routeNames;