import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
} from "reactstrap";
import "./PaymentModel.scss";
import Card1 from "../../assets/img/creditcard1.svg";
import Card2 from "../../assets/img/creditcard2.svg";
import { useDispatch } from "react-redux";
import {
  deleteWorkers,
  makeProfilePublic,
} from "../../store/provider/ProviderAction";
import { toast } from "react-toastify";

const PaymentModel = ({
  worker,
  setSelectedRows,
  selectedWorker,
  isOpen,
  setIsModelOpen,
  action,
  successModel,
  setSuccessModel,
}) => {
  const [selectWorker, setSelectWorker] = useState([]);
  useEffect(() => {
    const data = selectedWorker.map((el) => {
      return worker.find((ele) => ele.id == el);
    });
    setSelectWorker(data);
  }, []);

  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();
    if (action == "public") {
      dispatch(makeProfilePublic({ ids: selectedWorker }))
        .then((res) => {
          if (res.success) {
            setSuccessModel(true);
            setIsModelOpen(!isOpen);
            setSelectedRows([]);
          }
        })
        .catch((err) =>
          alert(err?.response?.data?.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          })
        );
    } else {
      dispatch(deleteWorkers({ ids: selectedWorker }))
        .then((res) => {
          if (res.success) {
            setSuccessModel(true);
            setIsModelOpen(!isOpen);
            setSelectedRows([]);
          }
        })
        .catch((err) =>
          alert(err?.response?.data?.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          })
        );
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} size="xl" centered className="">
        <ModalHeader className="px-5" toggle={() => setIsModelOpen(!isOpen)}>
          <h2>Payment</h2>
        </ModalHeader>

        <ModalBody className=" px-5    bg-transparent">
          <p className="mt-3">
            Listing in private mode is free, visible to NobelPage
            scouts/recruiters. Activate Public Mode for 30 days on WorkerPool
            for wider visibility to companies and visitors.
          </p>
          <div className="mt-4">
            <ol>
              {selectWorker?.map((ele) => {
                return (
                  <>
                    <li className="p-2 user_name" key={ele?.id}>
                      <div className="d-flex justify-content-between">
                        <p>
                          {ele?.user?.first_name}
                          {ele?.user?.last_name}
                        </p>
                        <p className="price">$10.00</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ol>
            <div className="horizental_line"></div>
            <div className="d-flex justify-content-between mt-3">
              <p className="user_name">Total amount</p>
              <p className="price">${selectWorker.length * 10}.00</p>
            </div>
          </div>

          <div className="">
            <form>
              <div className="row">
                <div className="col-12">
                  <FormGroup>
                    <label htmlFor="card_number">Card Number</label>
                    <Input
                      type="text"
                      // onChange={(e) => handleChange(e)}
                      name="card_number"
                      // placeholder="email"
                      // value={data?.email}
                    />
                  </FormGroup>
                </div>
                <div className="col-12">
                  <FormGroup>
                    <label htmlFor="card_name">Name of Card</label>
                    <Input
                      type="text"
                      // onChange={(e) => handleChange(e)}
                      name="card_name"
                      // placeholder="email"
                      // value={data?.email}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <FormGroup>
                    <label htmlFor="card_validity">Validity</label>
                    <Input
                      type="text"
                      // onChange={(e) => handleChange(e)}
                      name="card_validity"
                      // placeholder="email"
                      // value={data?.email}
                    />
                  </FormGroup>
                </div>
                <div className="col-6">
                  <FormGroup>
                    <label htmlFor="card_ccv">CCV</label>
                    <Input
                      type="text"
                      // onChange={(e) => handleChange(e)}
                      name="card_ccv"
                      // placeholder="email"
                      // value={data?.email}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="">
                <Input type="checkbox" /> Save this card
              </div>

              <div className="mt-4">
                <p>Saved Cards</p>
                <div className="d-flex justify-content-between mt-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <Input type="radio" name="saved_card" />
                    <div className="mx-2">
                      <p className="card_name">Ending in:...623</p>
                      <p className="card_expiry">Exp date: 12/26</p>
                    </div>
                  </div>
                  <div className="">
                    <img src={Card1} />
                  </div>
                </div>
                <div className="horizental_line"></div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <Input type="radio" name="saved_card" />
                    <div className="mx-2">
                      <p className="card_name">Ending in:...623</p>
                      <p className="card_expiry">Exp date: 12/26</p>
                    </div>
                  </div>
                  <div className="">
                    <img src={Card2} />
                  </div>
                </div>
              </div>
            </form>

            <div className="mt-3 d-flex justify-content-center">
              <button
                className="pay_btn px-4 py-2"
                onClick={(e) => handleClick(e)}
              >
                Pay Now
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PaymentModel;
