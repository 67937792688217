import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WorkerProfilePage from "./WorkerProfilePage";
import { useParams } from "react-router";
import { getWorkerProfile } from "../../../store/profile/ProfileAction";

function WorkerProfileContainer()  {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [isSeeMore, setIsSeeMore] = useState(true);
    const [phoneNum, setPhoneNum] = useState();
    const [mailTo, setMailTo] = useState();
    const [whatsAppTo, setWhatsAppTo] = useState();
    const [age, setAge] = useState(0);
    const [expRegion,setExpRegion] = useState();
    const [loginPopUp, setLoginPopUp] = useState(false);
    const [addIntroModal, setAddIntroModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");

  const { workerProfileData, nobelprofileData } = useSelector((state) => state.profile);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { isPageLoading } = useSelector((state) => state.loader);
  useEffect(() => {
   dispatch(getWorkerProfile(id))
  }, [])
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
     return age;
   
}

  useEffect(() => {
    if (workerProfileData?.user?.profile?.about?.length > 400) {
      setIsSeeMore(true);
    }
    if(workerProfileData?.user?.phone){
      setPhoneNum(`tel:${workerProfileData?.user?.phone}`)
    }
    if(workerProfileData?.email){
      setMailTo(`mailto:${workerProfileData?.email}`)
    }
    if(workerProfileData?.whatsapp){
      let newText = workerProfileData?.whatsapp?.split('-')[1] || workerProfileData?.whatsapp
        setWhatsAppTo(`https://api.whatsapp.com/send?phone=${newText}`);
    }
    if (workerProfileData?.user?.profile?.intro_video) {
      setVideoUrl(workerProfileData?.user?.profile?.intro_video);
    }
    // if(workerProfileData?.experience-region ){
    //   setExpRegion()
    // }
    setAge(getAge(workerProfileData?.user?.profile?.dob))
  }, [workerProfileData])

  
  const toggleSeeMore = () => {
    setIsSeeMore(!isSeeMore);
  };
  
  return (
    <WorkerProfilePage
    workerProfileData={workerProfileData}
    isSeeMore={isSeeMore}
    toggleSeeMore={toggleSeeMore}
    phoneNum={phoneNum}
    mailTo={mailTo}
    whatsAppTo={whatsAppTo}
    age={age}
    loginPopUp={loginPopUp}
    setLoginPopUp={setLoginPopUp}
    isLoggedIn={isLoggedIn}
    isPageLoading={isPageLoading}
    addIntroModal={addIntroModal}
    setAddIntroModal={setAddIntroModal}
    videoUrl={videoUrl}
    setVideoUrl={setVideoUrl}
    nobelprofileData={nobelprofileData}
    />
  )
}

export default WorkerProfileContainer