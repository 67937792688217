import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

 const PrivateRoute =({ children }) => {
    const location = useLocation();
     const { isLoggedIn } = useSelector(state => state.auth);
     console.log("isLoggedIn at private",isLoggedIn);
     const token = localStorage.getItem("accessToken");
    
     if (!token) {
     return <Navigate to="/login" state={{ from: location }} />
     } else
    return children;
}

export default PrivateRoute;