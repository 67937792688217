import axios from "axios";
//const accessToken = localStorage.getItem('accessToken');
export const call = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };

  const result = await axios({
    method,
    url: process.env.REACT_APP_API_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : ";",
    },
    responseType: "json",
  });

  return result;
};
