import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import smallWhiteLogo from "../../assets/img/smallWhiteLogo.png";
import mailIcon from "../../assets/img/mailIcon.png";
import shieldSecurity from "../../assets/img/shieldSecurity.png";
import eyeIcon from "../../assets/img/eyeIcon.png";
import "./WorkerPoolLogin.scss";
import { login } from "../../store/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../core/Inputs/FormInput";
import AskNobePage from "./component/AskNobePage";

const Login = (props) => {
  const { workerId, setLoginPopUp } = props;
  const [emailName, setEmailName] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [askNobelModal, setAskNobelModal] = useState(false);
  const [hide, setHide] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    {
      location?.state?.fromBI && setAskNobelModal(true);
    }
  }, []);

  const checkValidation = (type) => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let formIsValid = true;

    if (type === "emailName" || type === "all") {
      if (!emailName) {
        setErrorEmail("Please enter your email.");
        formIsValid = false;
      } else if (!emailvalid.test(emailName)) {
        setErrorEmail("Please enter your email in a valid format.");
        formIsValid = false;
      } else {
        setErrorEmail("");
      }
    }
    if (type === "password" || type === "all") {
      if (!password) {
        setErrorPassword("Please enter your password.");
        formIsValid = false;
      } else if (!passwordValid.test(password)) {
        setErrorPassword(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
      } else {
        setErrorPassword("");
      }
    }
    return formIsValid;
  };

  const handleFormSubmit = () => {
    dispatch(login(emailName, password, rememberMe))
      .then((res) => {
        if (res.success) {
          if (workerId) {
            navigate(`/workers/profile/${workerId}`);
            setLoginPopUp(false);
          } else navigate("/");
        } else console.log("Error", res.message);
      })
      .catch((e) => {
        let message = e.response.data.message;
        if (message === "No record found.") {
          setErrorMessage(
            "This email id is not registered, Please create an account"
          );
        } else setErrorMessage(message);
      });
  };

  // const handleFormSubmit = () => {
  //   // let registration_type = "email";
  //   console.log("handle start");
  //   dispatch(login(emailName, password));
  //   navigate("/")
  // }

  const submitLoginData = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (checkValidation("all")) {
      handleFormSubmit();
    }
  };

  // if (isLoggedIn) {
  //   try {
  //     if (workerId) {
  //       navigate(`/workers/profile/${workerId}`)
  //     } else navigate("/");
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // }
  return (
    <div className="">
      <div className="worker-login-box">
        <div className="d-flex justify-content-center align-items-center">
          <h2>Login</h2>
        </div>
        <form>
          <div
            className="noble-login-btn mb-3"
            onClick={() => {
              setAskNobelModal(true);
              setHide(true);
            }}
          >
            <img src={smallWhiteLogo} alt="logo" />
            Continue with NobelPage
          </div>
          {errorMessage && (
            <label className="label-input100 text-danger">{errorMessage}</label>
          )}
          <FormInput
            label="Email"
            icon={mailIcon}
            type="email"
            onChange={(e) => setEmailName(e.target.value)}
            placeholder="Enter email"
            onBlur={() => checkValidation("emailName")}
            error={errorEmail}
          />
          <div className="mb-3"></div>
          <FormInput
            label="Password"
            icon={shieldSecurity}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            onBlur={() => checkValidation("password")}
            error={errorPassword}
          />
          <div className="keep-logged">
            <label htmlFor="checkbox">
              <input
                type="checkbox"
                id="checkbox"
                onClick={() => setRememberMe(!rememberMe)}
              />
              Keep me logged in
              <span className="checkmark"></span>
            </label>
          </div>
          <button
            type="submit"
            className="login-button"
            value=""
            onClick={(e) => submitLoginData(e)}
          >
            Login to WorkerPool
          </button>
          <div className="terms-box">
            {/* <p>
            By clicking "Login to WorkerPool", you agree to NobelPage's Legal Terms
            including Terms of Use, Privacy Policy, Cookie Policy and Community
            GuidelinesTerms and Conditions & Privacy Policy
          </p> */}
            <p>
              By clicking &quot;Login to WorkerPool&quot;, you agree to
              NobelPage&apos;s{" "}
              <span
                onClick={() =>
                  window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/legal`)
                }
              >
                Legal Terms
              </span>{" "}
              including{" "}
              <span
                onClick={() =>
                  window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/legal`)
                }
              >
                Terms of Use,
              </span>{" "}
              <span
                onClick={() =>
                  window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/legal`)
                }
              >
                Privacy Policy,
              </span>{" "}
              <span
                onClick={() =>
                  window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/legal`)
                }
              >
                Cookie Policy{" "}
              </span>{" "}
              and
              <span
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_WEB_STAGE_URL}/community`
                  )
                }
              >
                {" "}
                Community Guidelines,{" "}
              </span>{" "}
              <span
                onClick={() =>
                  window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/legal`)
                }
              >
                {" "}
                Terms and Conditions
              </span>
              {" & "}
              <span
                onClick={() =>
                  window.open(`${process.env.REACT_APP_WEB_STAGE_URL}/legal`)
                }
              >
                {" "}
                Privacy Policy{" "}
              </span>
            </p>
          </div>
          <span className="create-account">
            Don’t have an account?{" "}
            <Link to="/worker/account-type">Create Account</Link>
          </span>
        </form>
      </div>

      {askNobelModal && (
        <AskNobePage
          modal={askNobelModal}
          toggle={() => setAskNobelModal(!askNobelModal)}
          type="worker"
          isLogin="true"
        />
      )}
    </div>
  );
};
export default Login;
