import {
  GET_JOB_LIST,
  GET_JOB_DETAIL,
  APPLY_JOB,
  APPLY_JOB_DETAIL,
  SAVED_JOB,
  GET_MY_JOB_LIST,
  UPDATE_JOB,
  GET_JOB_APPLICANT,
  UPDATE_JOB_DETAIL,
  GET_CATEGORY_LIST,
  GET_COUNTRY_LIST,
  GET_EXP_REGION,
  GET_PROFESSION_LIST,
  GET_RELIGION_LIST,
  GET_STATIC_DATA,
  GET_WORKER_LIST,
} from "../types";

const initialState = {
  jobList: {
    totalCount: 0,
    jobList: [],
  },
  jobDetail: {},
  myJob : [],
  applicant : []
};
const job = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_JOB_LIST:
      console.log(payload);
      return {
        ...state,
        jobList: { jobList: payload.jobList, totalCount: payload.totalCount },
      };
    case GET_JOB_DETAIL:
      return {
        ...state,
        jobDetail: payload,
      };
    case APPLY_JOB:
      console.log(payload?.job_id);
      const data = state?.jobList?.jobList?.map((ele) =>
        ele?.id == payload?.job_id
          ? {
              ...ele,
              appliedJob: {
                job_id: payload?.job_id,
                apply_status: payload?.apply_status,
              },
            }
          : ele
      );

      console.log();

      return {
        ...state,
        jobList: {
          ...state.jobList,
          jobList: data,
        },
      };

    case APPLY_JOB_DETAIL:
      return {
        ...state,
        jobDetail: {
          ...state?.jobDetail,
          appliedJob: {
            job_id: payload?.job_id,
            apply_status: payload?.apply_status,
          },
        },
      };

    case SAVED_JOB:
      console.log(payload);
      const job = state?.jobList?.jobList?.map((ele) => {
        if (ele?.id  == payload && ele?.savedJob?.status) {
          console.log("already saved", ele);
          return { ...ele, savedJob: null };
        } else if (ele?.id == payload && !ele?.savedJob) {
          console.log("saved");
          return {
            ...ele,
            savedJob: {
              job_id: payload,
              status: true,
            },
          };
        } else {
          return ele;
        }



      });

      return {
        ...state,
        jobList  :  {
          ...state.jobList,
          jobList: job,
      }
    }

      case GET_MY_JOB_LIST:
        return {
          ...state,
          myJob : payload
        }

        case UPDATE_JOB:
          const myj = state?.myJob?.filter((ele) => ele?.id !== payload) 
          return {
            ...state, 
            myJob : myj
          }


      case GET_JOB_APPLICANT:
        return {
          ...state,
          applicant : payload.applicant
        }

     case UPDATE_JOB_DETAIL:
      return {
        ...state,
        jobDetail : {...state?.jobDetail, ...payload}
      }


    default:
      return state;
  }
};

export default job;
