import React, { useEffect, useState } from "react";
import "./JobPaymentModel.scss";
import {
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

const JobPaymentSuccessModel = ({ successModel, setSuccessModel }) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        isOpen={successModel}
        size="lg"
        centered
        className="profilePermission"
      >
        <ModalBody className="p-3">
          <div className="modal-header d-flex flex-column  p-5 text-white">
            <h3 className="text-center">Job Posted Successfully</h3>
            <p className="text-center mt-3">
              Thank you for posting a job and creating an opportunity! Your job
              has been successfully posted for 30 days. Remember, you can always
              renew it.
            </p>
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="noble-login-btns mb-3 px-5 py-2"
                onClick={() => navigate("/")}
              >
                ok
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default JobPaymentSuccessModel;
