import { toast } from "react-toastify";
import { dashboardApi } from "../../api/dashboardApi";
import { initPageLoad, stopPageLoad } from "../loader/loaderActions";
import {
  FAIL_GET_WORKER_LIST,
  GET_CATEGORY_LIST,
  GET_COUNTRY_LIST,
  GET_EXP_REGION,
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_SUCCESS,
  GET_PROFESSION_LIST,
  GET_RELIGION_LIST,
  GET_STATIC_DATA,
  GET_WORKER_LIST,
  GET_JOB_LIST
} from "../types";

export const getWorkerList = (params) => (dispatch, getState) => {
  const { workerList } = getState().dashboard;
  dispatch(initPageLoad());
  return dashboardApi.getWorkers(params).then(
    (response) => {
      dispatch({
        type: GET_WORKER_LIST,
        // payload: response
        payload:
          workerList && workerList?.length && params?.skip && params?.limit
            ? [...workerList,  ...response?.data] 
            : response?.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error?.response?.data?.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getLandingPageData = (query) => (dispatch) => {
  return dashboardApi.getStaticData(query).then(
    (res) => {
      dispatch({
        type: GET_STATIC_DATA,
        payload: res?.data,
      });

      return Promise.resolve(res?.data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getExperienceRegion = () => (dispatch) => {
  return dashboardApi.getExpRegion().then(
    (res) => {
      dispatch({
        type: GET_EXP_REGION,
        payload: res?.data,
      });

      return Promise.resolve(res?.data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
export const getAllReligion = () => (dispatch) => {
  return dashboardApi.getReligionList().then(
    (res) => {
      dispatch({
        type: GET_RELIGION_LIST,
        payload: res?.data,
      });

      return Promise.resolve(res?.data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
export const getAllProfession = (query) => (dispatch) => {
  return dashboardApi.getProfessionList(query).then(
    (res) => {
      if (query)
        dispatch({
          type: GET_PROFESSION_LIST,
          payload: res?.data,
        });
      else
        dispatch({
          type: GET_CATEGORY_LIST,
          payload: res?.data,
        });

      return Promise.resolve(res?.data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getAllCountries = () => (dispatch) => {
  return dashboardApi.getCountryList().then(
    (res) => {
      dispatch({
        type: GET_COUNTRY_LIST,
        payload: res?.data,
      });

      return Promise.resolve(res?.data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getJobList = (params) => (dispatch, getState) => {
  const { jobList } = getState().dashboard;
  dispatch(initPageLoad());
  return dashboardApi.getJobs(params).then(
    (response) => {
      console.log(response, "responseresponse");
      dispatch({
        type: GET_JOB_LIST,
        // payload: response
        payload:
        {
          jobList : {jobList} && params?.skip && params?.limit
          ? [...jobList?.jobList, ...response?.data] 
          : response?.data,
          totalCount : response?.totalItem
        }
          
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FAIL_GET_WORKER_LIST,
      });
      console.log(error);
      toast.error(error?.response?.data?.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};
