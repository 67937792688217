import { call } from "./apiCall";

class ProviderApi {
  getWorkerOfPage(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/worker/worker-list`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  }

  ProfilePublic(ids) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/provider/make-public`,
            null,
            ids,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  DeleteProfile(ids) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/provider/remove-worker`,
            null,
            ids,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  getWorkerProfile(id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/public/worker/${id}`,
            null,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  getNobelProfile(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/profile/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  postUserDetails(userData, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/user/profile`,
            null,
            userData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  postWorkerUserDetails(userData, token, id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/worker/update/${id}`,
            null,
            userData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  getUserDetailsByID(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/profile/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
  getWPuserbyId(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/worker/${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          resolve(null);
        }
      })();
    });
  }

  publicYouProfile(token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/worker/public-profile`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          resolve(err);
        }
      })();
    });
  }

  addPage(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("post", `api/v1/page`, null, data, token);
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export const providerApi = new ProviderApi();
